<template>
    <div class="radar">
        <div class="title">
            <h2>{{ $t('menu.radar') }}</h2>
        </div>
        <SelectCity class="select-city" @setCity="setCity" :active="false" />

        <Nota :texto="$t('radar.nota')" />

        <div class="radar-menu">
            <div :class="{ active: select === '100' }" @click.prevent="setMenu('100')">
                100%
            </div>
            <div :class="{ active: select === '90' }" @click.prevent="setMenu('90')">
                90%
            </div>
            <div :class="{ active: select === '80' }" @click.prevent="setMenu('80')">
                80%
            </div>
            <div :class="{ active: select === '70' }" @click.prevent="setMenu('70')">
                70%
            </div>
            <div :class="{ active: select === '60' }" @click.prevent="setMenu('60')">
                60%
            </div>
            <div :class="{ active: select === '50' }" @click.prevent="setMenu('50')">
                50%
            </div>
            <div :class="{ active: select === '40' }" @click.prevent="setMenu('40')">
                40%
            </div>
            <div :class="{ active: select === '30' }" @click.prevent="setMenu('30')">
                30%
            </div>
            <div :class="{ active: select === '20' }" @click.prevent="setMenu('20')">
                20%
            </div>
            <div :class="{ active: select === '10' }" @click.prevent="setMenu('10')">
                10%
            </div>
            <div :class="{ active: select === '0' }" @click.prevent="setMenu('0')">
                0%
            </div>

        </div>
        <div class="btn btn-velocity" href="#" @click.prevent="selectVelocity" v-if="pokemonsFilter.length">
            {{
            velocity
            ? $t('missao.velocityAtivado')
            : $t('missao.velocityDesativado')
            }}
        </div>
        <div class="nota-pkm" v-if="!pokemonsFilter.length">
            <i class="fa fa-info-circle"></i>
            {{ $t('radar.nenhum') }}
        </div>
        <NotaShiny />
        <div>
            <Loading :active="loading" />
            <div>
                <div class="pkm" v-for="(pokemon, key) in pokemonsFilter" :key="key"
                    :class="{ active: pokemon.iv == 100 }">
                    <div class="img">
                        <img class="igg" :src="pokemon.base"  @error="getImage" />
                        <!--  {{pokemon}} -->
                    </div>
                    <div class="info">
                        <div class="name">
                            <div class="shiny" v-if="setShiny(pokemon.pokemon_id)">
                                Shiny
                            </div>
                            <div>#{{ pokemon.name[0][0] }} - {{ pokemon.name[0][1] }}</div>
                        </div>
                        <div class="iv">
                            <div class="genero">
                                <img src="../assets/icons/male_l.png" v-if="pokemon.gender === 1" />
                                <img src="../assets/icons/female_l.png" v-if="pokemon.gender === 2" />
                            </div>
                            <div class="info-level">L{{ pokemon.level }}</div>
                            <div class="info-iv">
                                IV<strong>{{ pokemon.iv }}</strong>
                            </div>
                            <div class="info-teste">
                                {{ pokemon.attack }}/{{ pokemon.defence }}/{{ pokemon.stamina }}
                            </div>
                        </div>
                        <div class="cp">
                            <div class="pc">
                                CP <strong>{{ pokemon.cp }}</strong>
                            </div>
                            <div class="golpe">
                                {{ movesDict[pokemon.move1] }} <br />
                                {{ movesDict[pokemon.move2] }}
                            </div>
                        </div>
                    </div>
                    <div class="coordenadas">
                        <div class="coordenada">
                            {{ pokemon.coord }}
                        </div>
                        <div style="display: flex; justify-content: center;">
                            <div v-if="!velocity">
                                <vue-flip active-click width="100px" height="45px" class="btn-content">
                                    <template v-slot:front>
                                        <div class="btn" @click="getCopy(pokemon.coord)">
                                            {{ $t('locais.copiar') }}
                                        </div>
                                    </template>
                                    <template v-slot:back>
                                        <div class="btn" @click="getCopy(pokemon.coord)">=)</div>
                                    </template>
                                </vue-flip>
                            </div>
                            <div v-else>
                                <!--  <td v-else> -->
                                <vue-flip active-click width="100px" height="45px" class="btn-content"
                                    v-model="pokemon.copy">
                                    <template v-slot:front>
                                        <div class="btn" @click="getCopyVelocity(pokemon)">
                                            {{ $t('missao.Abrir') }}
                                        </div>
                                    </template>
                                    <template v-slot:back>
                                        <div class="btn" @click="getCopyVelocity(pokemon)">
                                            =)
                                        </div>
                                    </template>
                                </vue-flip>
                            </div>
                        </div>
                        <div class="tempo">
                            <countdown :time="pokemon.time * 1000" :transform="transform" v-if="pokemon.time > 0">
                                <template slot-scope="props">
                                    DSP: {{ props.minutes }}:{{ props.seconds }}</template>
                            </countdown>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import moment from 'moment'
import axios from 'axios'
import name from '../components/Pogo/name'
import dados from '../components/Pogo/shiny'
import SelectCity from '../components/Select'
import Loading from '../components/Loading'
import VueFlip from 'vue-flip'
import Countdown from '@chenfengyuan/vue-countdown'
import { getAnalytics, logEvent } from 'firebase/analytics'
import Nota from '../components/Nota'
import NotaShiny from '../components/NotaShiny'

export default {
    components: {
        SelectCity,
        Loading,
        VueFlip,
        Countdown,
        Nota,
        NotaShiny
    },
    computed: {
        pokemonsFilter() {
            return this.select === '90' || this.select === '80' || this.select === '70' || this.select === '60' || this.select === '50' || this.select === '40' || this.select === '30' || this.select === '20' || this.select === '10' || this.select === '0'
                ? this.pokemons.length ? this.pokemons.filter(i => i.iv >= this.select && i.iv <= Number(this.select) + 10) : []
                : this.pokemons.filter(i => i.iv == 100)
        }
    },
    data: () => ({
        pokemons: [],
        loading: false,
        velocity: false,
        lcl: 'us',
        select: '90',
        iv: '90',
        movesDict: {
            '1': 'Thunder Shock',
            '2': 'Quick Attack',
            '3': 'Scratch',
            '4': 'Ember',
            '5': 'Vine Whip',
            '6': 'Tackle',
            '7': 'Razor Leaf',
            '8': 'Take Down',
            '9': 'Water Gun',
            '10': 'Bite',
            '11': 'Pound',
            '12': 'Double Slap',
            '13': 'Wrap',
            '14': 'Hyper Beam',
            '15': 'Lick',
            '16': 'Dark Pulse',
            '17': 'Smog',
            '18': 'Sludge',
            '19': 'Metal Claw',
            '20': 'Vice Grip',
            '21': 'Flame Wheel',
            '22': 'Megahorn',
            '23': 'Wing Attack',
            '24': 'Flamethrower',
            '25': 'Sucker Punch',
            '26': 'Dig',
            '27': 'Low Kick',
            '28': 'Cross Chop',
            '29': 'Psycho Cut',
            '30': 'Psybeam',
            '31': 'Earthquake',
            '32': 'Stone Edge',
            '33': 'Ice Punch',
            '34': 'Heart Stamp',
            '35': 'Discharge',
            '36': 'Flash Cannon',
            '37': 'Peck',
            '38': 'Drill Peck',
            '39': 'Ice Beam',
            '40': 'Blizzard',
            '41': 'Air Slash',
            '42': 'Heat Wave',
            '43': 'Twineedle',
            '44': 'Poison Jab',
            '45': 'Aerial Ace',
            '46': 'Drill Run',
            '47': 'Petal Blizzard',
            '48': 'Mega Drain',
            '49': 'Bug Buzz',
            '50': 'Poison Fang',
            '51': 'Night Slash',
            '52': 'Slash',
            '53': 'Bubble Beam',
            '54': 'Submission',
            '55': 'Karate Chop',
            '56': 'Low Sweep',
            '57': 'Aqua Jet',
            '58': 'Aqua Tail',
            '59': 'Seed Bomb',
            '60': 'Psyshock',
            '61': 'Rock Throw',
            '62': 'Ancient Power',
            '63': 'Rock Tomb',
            '64': 'Rock Slide',
            '65': 'Power Gem',
            '66': 'Shadow Sneak',
            '67': 'Shadow Punch',
            '68': 'Shadow Claw',
            '69': 'Ominous Wind',
            '70': 'Shadow Ball',
            '71': 'Bullet Punch',
            '72': 'Magnet Bomb',
            '73': 'Steel Wing',
            '74': 'Iron Head',
            '75': 'Parabolic Charge',
            '76': 'Spark',
            '77': 'Thunder Punch',
            '78': 'Thunder',
            '79': 'Thunderbolt',
            '80': 'Twister',
            '81': 'Dragon Breath',
            '82': 'Dragon Pulse',
            '83': 'Dragon Claw',
            '84': 'Disarming Voice',
            '85': 'Draining Kiss',
            '86': 'Dazzling Gleam',
            '87': 'Moonblast',
            '88': 'Play Rough',
            '89': 'Cross Poison',
            '90': 'Sludge Bomb',
            '91': 'Sludge Wave',
            '92': 'Gunk Shot',
            '93': 'Mud Shot',
            '94': 'Bone Club',
            '95': 'Bulldoze',
            '96': 'Mud Bomb',
            '97': 'Fury Cutter',
            '98': 'Bug Bite',
            '99': 'Signal Beam',
            '100': 'X Scissor',
            '101': 'Flame Charge',
            '102': 'Flame Burst',
            '103': 'Fire Blast',
            '104': 'Brine',
            '105': 'Water Pulse',
            '106': 'Scald',
            '107': 'Hydro Pump',
            '108': 'Psychic',
            '109': 'Psystrike',
            '110': 'Ice Shard',
            '111': 'Icy Wind',
            '112': 'Frost Breath',
            '113': 'Absorb',
            '114': 'Giga Drain',
            '115': 'Fire Punch',
            '116': 'Solar Beam',
            '117': 'Leaf Blade',
            '118': 'Power Whip',
            '119': 'Splash',
            '120': 'Acid',
            '121': 'Air Cutter',
            '122': 'Hurricane',
            '123': 'Brick Break',
            '124': 'Cut',
            '125': 'Swift',
            '126': 'Horn Attack',
            '127': 'Stomp',
            '128': 'Headbutt',
            '129': 'Hyper Fang',
            '130': 'Slam',
            '131': 'Body Slam',
            '132': 'Rest',
            '133': 'Struggle',
            '134': 'Scald',
            '135': 'Hydro Pump',
            '136': 'Wrap Green',
            '137': 'Wrap Pink',
            '200': 'Fury Cutter',
            '201': 'Bug Bite',
            '202': 'Bite',
            '203': 'Sucker Punch',
            '204': 'Dragon Breath',
            '205': 'Thunder Shock',
            '206': 'Spark',
            '207': 'Low Kick',
            '208': 'Karate Chop',
            '209': 'Ember',
            '210': 'Wing Attack',
            '211': 'Peck',
            '212': 'Lick',
            '213': 'Shadow Claw',
            '214': 'Vine Whip',
            '215': 'Razor Leaf',
            '216': 'Mud Shot',
            '217': 'Ice Shard',
            '218': 'Frost Breath',
            '219': 'Quick Attack',
            '220': 'Scratch',
            '221': 'Tackle',
            '222': 'Pound',
            '223': 'Cut',
            '224': 'Poison Jab',
            '225': 'Acid',
            '226': 'Psycho Cut',
            '227': 'Rock Throw',
            '228': 'Metal Claw',
            '229': 'Bullet Punch',
            '230': 'Water Gun',
            '231': 'Splash',
            '232': 'Water Gun',
            '233': 'Mud Slap',
            '234': 'Zen Headbutt',
            '235': 'Confusion',
            '236': 'Poison Sting',
            '237': 'Bubble',
            '238': 'Feint Attack',
            '239': 'Steel Wing',
            '240': 'Fire Fang',
            '241': 'Rock Smash',
            '242': 'Transform',
            '243': 'Counter',
            '244': 'Powder Snow',
            '245': 'Close Combat',
            '246': 'Dynamic Punch',
            '247': 'Focus Blast',
            '248': 'Aurora Beam',
            '249': 'Charge Beam',
            '250': 'Volt Switch',
            '251': 'Wild Charge',
            '252': 'Zap Cannon',
            '253': 'Dragon Tail',
            '254': 'Avalanche',
            '255': 'Air Slash',
            '256': 'Brave Bird',
            '257': 'Sky Attack',
            '258': 'Sand Tomb',
            '259': 'Rock Blast',
            '260': 'Infestation',
            '261': 'Struggle Bug',
            '262': 'Silver Wind',
            '263': 'Astonish',
            '264': 'Hex',
            '265': 'Night Shade',
            '266': 'Iron Tail',
            '267': 'Gyro Ball',
            '268': 'Heavy Slam',
            '269': 'Fire Spin',
            '270': 'Overheat',
            '271': 'Bullet Seed',
            '272': 'Grass Knot',
            '273': 'Energy Ball',
            '274': 'Extrasensory',
            '275': 'Futuresight',
            '276': 'Mirror Coat',
            '277': 'Outrage',
            '278': 'Snarl',
            '279': 'Crunch',
            '280': 'Foul Play',
            '281': 'Hidden Power',
            '282': 'Take Down',
            '283': 'Waterfall',
            '284': 'Surf',
            '285': 'Draco Meteor',
            '286': 'Doom Desire',
            '287': 'Yawn',
            '288': 'Psycho Boost',
            '289': 'Origin Pulse',
            '290': 'Precipice Blades',
            '291': 'Present',
            '292': 'Weather Ball Fire',
            '293': 'Weather Ball Ice',
            '294': 'Weather Ball Rock',
            '295': 'Weather Ball Water',
            '296': 'Frenzy Plant',
            '297': 'Smack Down',
            '298': 'Blast Burn',
            '299': 'Hydro Cannon',
            '300': 'Last Resort',
            '301': 'Meteor Mash',
            '302': 'Skull Bash',
            '303': 'Acid Spray',
            '304': 'Earth Power',
            '305': 'Crabhammer',
            '306': 'Lunge',
            '307': 'Crush Claw',
            '308': 'Octazooka',
            '309': 'Mirror Shot',
            '310': 'Super Power',
            '311': 'Fell Stinger',
            '312': 'Leaf Tornado',
            '313': 'Leech Life',
            '314': 'Drain Punch',
            '315': 'Shadow Bone',
            '316': 'Muddy Water',
            '317': 'Blaze Kick',
            '318': 'Razor Shell',
            '319': 'Power Up Punch',
            '320': 'Charm',
            '321': 'Giga Impact',
            '322': 'Frustration',
            '323': 'Return',
            '324': 'Synchronoise',
            '325': 'Lock On',
            '326': 'Thunder Fang',
            '327': 'Ice Fang',
            '328': 'Horn Drill',
            '329': 'Fissure',
            '330': 'Sacred Sword',
            '331': 'Flying Press',
            '332': 'Aura Sphere',
            '333': 'Payback',
            '334': 'Rock Wrecker',
            '335': 'Aeroblast',
            '336': 'Techno Blast Normal',
            '337': 'Techno Blast Burn',
            '338': 'Techno Blast Chill',
            '339': 'Techno Blast Water',
            '340': 'Techno Blast Shock',
            '341': 'Fly',
            '342': 'V Create',
            '343': 'Leaf Storm',
            '344': 'Tri Attack',
            '345': 'Gust',
            '346': 'Incinerate',
            '347': 'Dark Void',
            '348': 'Feather Dance',
            '349': 'Fiery Dance',
            '350': 'Fairy Wind',
            '351': 'Relic Song',
            '352': 'Weather Ball'
        }
    }),
    methods: {
        setMenu(select) {
            this.select = select
            this.iv = select
            this.getDados(select)
        },
        setCity(item) {
            this.lcl = item.slug
            this.pokemons = []
            this.getDados('90')
        },
        arendondar(num, dec) {
            return (+(Math.round(num + 'e+' + dec) + 'e-' + dec)).toFixed(dec)
        },
        getLugar(lugar) {
            switch (lugar) {
                case 'ca':
                    return 'Vancouver'
                case 'us':
                    return 'Nova York'
                case 'gb':
                    return 'Londres'
                case 'sg':
                    return 'Singapura'
                case 'au':
                    return 'Sydney'
                default:
                    return ''
            }
        },
        getDsp(epoch) {
            var hours = epoch / 3600,
                minutes = (hours % 1) * 60
            return (
                (Math.floor(hours) !== 0 ? `${Math.floor(hours)}h` : '') +
                `${Math.floor(minutes)}m`
            )
        },
        leftPad(value, totalWidth, paddingChar) {
            var length = totalWidth - value.toString().length + 1
            return Array(length).join(paddingChar || '0') + value
        },
        getSrc(i) {
            let v
            if (i.value2) {
                v = `${this.leftPad(i.value2, 3)}_00`
            } else if (i.value5) {
                v = `${this.leftPad(i.pokemon_id, 3)}_${i.value5}`
            } else
                if (i.pokemon_id) {
                    let nn
                    if (i.name[0][1].match(/Alola/)) {
                        nn = '.fALOLA'
                    } else if (i.name[0][1].match(/Outono/)) {
                        nn = '.fAUTUMN'
                    } else if (i.name[0][1].match(/Primavera/)) {
                        nn = '.fSPRING'
                    } else if (i.name[0][1].match(/Verão/)) {
                        nn = '.fSUMMER'
                    } else if (i.name[0][1].match(/Inverno/)) {
                        nn = '.fWINTER'
                    }
                    else if (i.name[0][1].match(/Galarian/)) {
                        nn = '.fGALARIAN'
                    } else {
                        if (i.pokemon_id == 327) {
                            nn = '.f00'
                        } else if (i.pokemon_id == 412) {
                            nn = '.fBURMY_PLANT'
                        }
                        else if (i.pokemon_id == 412) {
                            nn = '.fBURMY_PLANT'
                        }
                        else {
                            nn = ''
                        }
                    }
                    v = i.pokemon_id + nn
                }
            const url = 'https://images.weserv.nl/?dpr=1&w=200&il&url=https://cdn.jsdelivr.net/gh/PokeMiners/pogo_assets/Images/Pokemon/Addressable%20Assets/'
            if (!i.value4) {
                return `${url}pm${v}${i.shiny ? '.s' : ''}.icon.png`
            } else {
                return i.value4
            }
        },
        async getDados(iv) {
            this.pokemons = []
            this.loading = true
            const pokemons = await axios(`${this.$url}/radar`, {
                params: {
                    lc: this.lcl,
                    iv: iv
                }
            }).catch(() => {
                alert('Aconteceu um erro!')
                this.loading = false
            })
            let dadosI = pokemons.data.pokemons.map(i => {
                i.time = i.despawn - moment().unix()
                i.name = Object.entries(name).filter(x => i.pokemon_id == x[0])
                i.shiny = this.setShiny(i.pokemon_id)
                i.base = this.getSrc(i)
                //    ? require(`../assets/pokemon/${i.pokemon_id}.png`)
                //    : null
                i.coord = `${this.arendondar(i.lat, 6)},${this.arendondar(i.lng, 6)}`
                i.iv = Math.floor(((i.attack + i.defence + i.stamina) / 45) * 100)
                return i
            })
            // eslint-disable-next-line no-unused-vars
            var coords = `Coords GO\nRaids\n${this.getLugar(
                this.lcl
            )}\n${moment().format('L')}\n`
            const arrayTag = ['C', 'O', 'O', 'R', 'D', 'S', 'G', 'O']
            var inicio = 0
            var rocket = []
            var totalArrayTag = arrayTag.length

            for (let item of dadosI) {
                var end = item.despawn - moment().unix()
                if (end > 300) {
                    coords += `${arrayTag[inicio]} ${this.arendondar(
                        item.lat,
                        6
                    )},${this.arendondar(item.lng, 6)} DSP: ${this.getDsp(end)}\n`

                    var rocketNew = {}
                    rocketNew.logo = arrayTag[inicio]
                    rocketNew.coord = `${this.arendondar(item.lat, 6)},${this.arendondar(
                        item.lng,
                        6
                    )}`
                    rocketNew.dsp = this.getDsp(end)
                    rocketNew.end = end
                    rocketNew.copy = false
                    rocketNew.time = end
                    rocketNew.copy3 = false
                    rocketNew.id = Math.random()
                    rocketNew.attack = item.attack
                    rocketNew.base = item.base
                    rocketNew.coord = item.coord
                    rocketNew.costume = item.costume
                    rocketNew.cp = item.cp
                    rocketNew.defence = item.defence
                    rocketNew.despawn = item.despawn
                    rocketNew.disguise = item.disguise
                    rocketNew.form = item.form
                    rocketNew.gender = item.gender
                    rocketNew.iv = item.iv
                    rocketNew.lat = item.lat
                    rocketNew.level = item.level
                    rocketNew.lng = item.lng
                    rocketNew.move1 = item.move1
                    rocketNew.move2 = item.move2
                    rocketNew.name = item.name
                    rocketNew.pokemon_id = item.pokemon_id
                    rocketNew.shiny = item.shiny
                    rocketNew.stamina = item.stamina
                    rocketNew.time = item.time
                    rocketNew.weather = item.weather

                    rocket.push(rocketNew)
                    inicio = inicio === totalArrayTag - 1 ? 0 : inicio + 1
                }
                if (rocket.length >= 48) {
                    break
                }
            }

            this.pokemons = rocket
            this.loading = false
        },
        transform(props) {
            Object.entries(props).forEach(([key, value]) => {
                const digits = value < 10 ? `0${value}` : value
                props[key] = `${digits}`
            })
            return props
        },
        getCopy(r, key) {
            key
            this.$copyText(r).then(
                () => null,
                () => alert('Erro')
            )
        },
        setShiny(dex) {
            return dados
                .filter(i => i.dex === Number(dex) && i.dex !== 618 && i.dex !== 562)
                .some(i => i.shiny_released || i.released_date)
        },
        selectVelocity() {
            localStorage.setItem('_velocityPkm90', !this.velocity)
            this.velocity = !this.velocity
        },
        getCopyVelocity(r) {
            window.open(`https://pk.md/${r.coord}`, '_blank')
        },
        getImage(e) {
            e.target.src = require('../assets/pokemon/0.png')
        },
    },
    mounted() {
        document.title = 'APP CoordsGo.com - Radar'
        const analytics = getAnalytics(this.$fire)
        logEvent(analytics, 'screen_view', {
            firebase_screen: `APP - ${this.$route.meta.lang} ${this.$route.meta.titulo}`,
            firebase_screen_class: 'Radar'
        })
        if (localStorage.getItem('_velocityPkm90')?.length) {
            this.velocity =
                localStorage.getItem('_velocityPkm90') === 'true' ? true : false
        } else {
            this.velocity = false
        }
        this.getDados('90')
    }
}
</script>

<style lang="scss" scoped>
.title {
    h2 {
        display: flex;
        justify-content: space-between;
        color: #673bb7;
        margin: 10px 0;
        font-size: 23px;
        border-bottom: 1px solid #673bb7;
        font-weight: bold;
    }
}
.select-city {
    margin-top: 10px;
}

h2 {
    display: flex;
    justify-content: space-between;
    color: #673bb7;
    margin: 10px 0;
    font-size: 21px;
}

.radar {
    .radar-menu {
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        grid-auto-flow: row;
        gap: 10px;

        div {
            border: 1px solid #ccc;
            padding: 10px;
            width: 60px;
            border-radius: 5px;
            cursor: pointer;
            align-self: center;
            justify-self: center;
        }

        .active {
            background-color: #673ab7;
            color: #fff;
        }
    }

    h3 {
        padding-bottom: 5px;
        padding-top: 5px;
        font-size: 20px;
        font-weight: bold;
    }

    .pkm {
        &.active {
            background-color: #f1e8cf;
        }

        .tempo {
            font-size: 12px;
            margin-top: 5px;
        }

        border: 1px solid #ccc;
        margin-top: 20px;
        border-radius: 5px;
        display: flex;

        .img {
            flex: 0.5;
            display: flex;
            align-items: center;
            justify-content: center;

        }

        .info {
            padding: 5px 0 5px 0;
            flex: 1;
            justify-content: center;
            display: flex;
            flex-direction: column;

            .name {
                display: flex;
                align-items: center;
                justify-content: flex-start;
                font-size: 12px;
                font-weight: bold;
                color: #2c3e50;
            }

            .iv {
                display: flex;
                align-items: center;
                justify-content: flex-start;
                font-size: 12px;

                .info-level {
                    margin-right: 5px;
                }

                .info-iv {
                    margin-right: 5px;
                }

                .info-teste {
                    margin-right: 5px;
                }
            }

            .genero {
                margin-right: 3px;

                img {
                    height: 16px;
                }
            }
        }

        .cp {
            display: flex;
            align-items: center;
            justify-content: space-between;
            font-size: 12px;

            .pc {
                flex: 1;
                margin-right: 5px;
                display: flex;
            }

            .golpe {
                display: flex;
                flex: 1;
                font-size: 10px;
            }
        }

        .coordenadas {
            padding: 5px 5px 10px 0;
            flex: 0.8;
            display: flex;
            justify-content: center;
            flex-direction: column;

            .coordenada {
                margin-top: 5px;
                margin-bottom: 5px;
                font-size: 10px;
                background-color: #ececec;
                padding: 3px;
                border-radius: 5px;
                box-sizing: border-box;
            }
        }
    }
}

.shiny {
    background: #ffc107;
    color: rgb(255, 255, 255);
    justify-content: center;
    display: flex;
    align-items: center;
    padding: 0 5px;
    border-radius: 5px;
    text-transform: uppercase;
    margin-right: 5px;
    font-size: 8px;
    height: 14px !important;
}

.nota-pkm {
    margin-top: 20px;
}

.btn-velocity {
    color: #fff;
    background: #673ab7;
    width: 130px !important;
    margin: 0 auto;
    text-align: center;
    height: 25px !important;
    font-size: 12px;
    margin-bottom: 20px;
    margin-top: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 5px;
}
</style>

<style lang="scss">
.back {
    background-color: #ffc107;
}

.front {
    background-color: #673ab7;
}

.back,
.front {
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    width: 100%;
    height: 100%;
}

.back {
    transform: rotateY(180deg);
}

.back,
.front {
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    position: absolute;
    top: 0;
    left: 0;
}

.back,
.flipper,
.front {
    transform-style: preserve-3d;
    border-radius: 5px;
}

*,
:after,
:before {
    border-width: 0;
    border-style: solid;
    border-color: #e2e8f0;
}

.igg {
    max-height: 40px;
    max-width: 40px;
}
</style>
