export default [
    {
        dex: 1,
        family: 'Bulbasaur',
        released_date: '2018/03/25'
    },
    {
        dex: 1,
        shiny_released: true,
        fn: 'pm0001_00_pgo_fall2019',
        isotope: '_f19',
        family: 'Bulbasaur_f19'
    },
    {
        dex: 1,
        shiny_released: true,
        isotope: '_11',
        family: 'Bulbasaur_11'
    },
    {
        dex: 1,
        shiny_released: true,
        isotope: '_14',
        family: 'Bulbasaur_14'
    },
    {
        dex: 2,
        family: 'Bulbasaur',
        released_date: '2018/03/25'
    },
    {
        dex: 3,
        family: 'Bulbasaur',
        released_date: '2018/03/25'
    },
    {
        dex: 3,
        type: '_51',
        family: 'Bulbasaur',
        name_suffix: '(M)',
        released_date: '2018/03/25'
    },
    {
        dex: 4,
        family: 'Charmander',
        released_date: '2018/05/19'
    },
    {
        dex: 4,
        shiny_released: true,
        fn: 'pm0004_00_pgo_fall2019',
        isotope: '_f19',
        family: 'Charmander_f19'
    },
    {
        dex: 4,
        shiny_released: true,
        isotope: '_11',
        family: 'Charmander_11'
    },
    {
        dex: 5,
        isotope: '_11',
        released_date: '2022/07/06',
        aa_fn: 'pm5.cJAN_2020_NOEVOLVE',
        family: 'Charmander_11',
        cfn1: 'https://cdn.jsdelivr.net/gh/PokeMiners/pogo_assets/Images/Pokemon%20-%20256x256/Addressable%20Assets/pm5.cJAN_2020_NOEVOLVE.s.icon.png'
    },
    {
        dex: 6,
        isotope: '_11',
        released_date: '2022/07/06',
        aa_fn: 'pm6.cJAN_2020_NOEVOLVE',
        family: 'Charmander_11',
        cfn1: 'https://cdn.jsdelivr.net/gh/PokeMiners/pogo_assets/Images/Pokemon%20-%20256x256/Addressable%20Assets/pm6.cJAN_2020_NOEVOLVE.s.icon.png'
    },
    {
        dex: 4,
        shiny_released: true,
        isotope: '_14',
        family: 'Charmander_14'
    },
    {
        dex: 5,
        family: 'Charmander',
        released_date: '2018/05/19'
    },
    {
        dex: 6,
        family: 'Charmander',
        released_date: '2018/05/19'
    },
    {
        dex: 6,
        type: '_51',
        name_suffix: '(M-X)',
        family: 'Charmander',
        released_date: '2018/05/19'
    },
    {
        dex: 6,
        type: '_52',
        name_suffix: '(M-Y)',
        family: 'Charmander',
        released_date: '2018/05/19'
    },
    {
        dex: 7,
        family: 'Squirtle',
        released_date: '2018/07/08'
    },
    {
        dex: 8,
        family: 'Squirtle',
        released_date: '2018/07/08'
    },
    {
        dex: 9,
        family: 'Squirtle',
        released_date: '2018/07/08'
    },
    {
        dex: 9,
        type: '_51',
        name_suffix: '(M)',
        family: 'Squirtle',
        released_date: '2018/07/08'
    },
    {
        dex: 7,
        isotope: '_05',
        shiny_released: true,
        family: 'Squirtle_05'
    },
    {
        dex: 8,
        isotope: '_05',
        shiny_released: true,
        family: 'Squirtle_05'
    },
    {
        dex: 9,
        isotope: '_05',
        shiny_released: true,
        family: 'Squirtle_05'
    },
    {
        dex: 7,
        shiny_released: true,
        fn: 'pm0007_00_pgo_fall2019',
        isotope: '_f19',
        family: 'Squirtle_f19'
    },
    {
        dex: 7,
        shiny_released: true,
        isotope: '_11',
        order: 11,
        family: 'Squirtle_11'
    },
    {
        dex: 7,
        shiny_released: true,
        isotope: '_14',
        order: 14,
        family: 'Squirtle_14'
    },
    {
        dex: 10,
        family: 'Caterpie',
        released_date: '2018/11/01'
    },
    {
        dex: 11,
        family: 'Caterpie',
        released_date: '2018/11/01'
    },
    {
        dex: 12,
        family: 'Caterpie',
        released_date: '2018/11/01'
    },
    {
        dex: 12,
        family: 'Caterpie_2021_FASHION',
        aa_fn: 'pm12.cFASHION_2021_NOEVOLVE',
        cfn0:
            'https://raw.githubusercontent.com/PokeMiners/pogo_assets/master/Images/Pokemon%20-%20256x256/Addressable%20Assets/pm12.cFASHION_2021_NOEVOLVE.icon.png',
        cfn1:
            'https://raw.githubusercontent.com/PokeMiners/pogo_assets/master/Images/Pokemon%20-%20256x256/Addressable%20Assets/pm12.cFASHION_2021_NOEVOLVE.s.icon.png',

        isotope: '_2021_FASHION',
        released_date: '2021/09/21'
    },
    {
        dex: 13,
        family: 'Weedle',
        released_date: '2020/06/19'
    },
    {
        dex: 14,
        family: 'Weedle',
        released_date: '2020/06/19'
    },
    {
        dex: 15,
        family: 'Weedle',
        released_date: '2020/06/19'
    },
    {
        dex: 15,
        type: '_51',
        name_suffix: '(M)',
        family: 'Weedle',
        released_date: '2020/06/19'
    },
    {
        dex: 16,
        family: 'Pidgey',
        released_date: '2019/02/26'
    },
    {
        dex: 17,
        family: 'Pidgey',
        released_date: '2019/02/26'
    },
    {
        dex: 18,
        family: 'Pidgey',
        released_date: '2019/02/26'
    },
    {
        dex: 18,
        type: '_51',
        name_suffix: '(M)',
        family: 'Pidgey',
        released_date: '2019/02/26'
    },
    {
        dex: 19,
        family: 'Rattata',
        released_date: '2019/02/26'
    },
    {
        dex: 20,
        family: 'Rattata',
        released_date: '2019/02/26'
    },
    {
        dex: 20,
        isotope: '_11',
        family: 'Rattata',
        released_date: '2019/02/26'
    },
    {
        dex: 19,
        type: '_61',
        family: 'Rattata_61',
        released_date: '2019/06/28'
    },
    {
        dex: 20,
        type: '_61',
        family: 'Rattata_61',
        released_date: '2019/06/28'
    },
    {
        dex: 21,
        released_date: '2021/02/20',
        family: 'Spearow'
    },
    {
        dex: 22,
        released_date: '2021/02/20',
        family: 'Spearow'
    },
    {
        dex: 23,
        family: 'Ekans',
        released_date: '2019/07/25'
    },
    {
        dex: 24,
        family: 'Ekans',
        released_date: '2019/07/25'
    },
    {
        dex: 25,
        family: 'Pikachu',
        released_date: '2017/08/15'
    },
    {
        dex: 26,
        family: 'Pikachu',
        released_date: '2017/08/15'
    },
    {
        dex: 25,
        isotope: '_02',
        shiny_released: true,
        family: 'Pikachu_02'
    },
    {
        dex: 26,
        isotope: '_02',
        shiny_released: true,
        family: 'Pikachu_02'
    },
    {
        dex: 25,
        isotope: '_03',
        shiny_released: true,
        family: 'Pikachu_03'
    },
    {
        dex: 26,
        isotope: '_03',
        shiny_released: true,
        family: 'Pikachu_03'
    },
    {
        dex: 25,
        isotope: '_06',
        shiny_released: true,
        family: 'Pikachu_06'
    },
    {
        dex: 26,
        isotope: '_06',
        shiny_released: true,
        family: 'Pikachu_06'
    },
    {
        dex: 25,
        isotope: '_04',
        shiny_released: true,
        family: 'Pikachu_04'
    },
    {
        dex: 26,
        isotope: '_04',
        shiny_released: true,
        family: 'Pikachu_04'
    },
    {
        dex: 25,
        isotope: '_01',
        shiny_released: true,
        family: 'Pikachu_01'
    },
    {
        dex: 26,
        isotope: '_01',
        shiny_released: true,
        family: 'Pikachu_01'
    },
    {
        dex: 25,
        isotope: '_05',
        shiny_released: true,
        family: 'Pikachu_05'
    },
    {
        dex: 26,
        isotope: '_05',
        shiny_released: true,
        family: 'Pikachu_05'
    },
    {
        dex: 25,
        isotope: '_07',
        shiny_released: true,
        family: 'Pikachu_07'
    },
    {
        dex: 26,
        isotope: '_07',
        shiny_released: true,
        family: 'Pikachu_07'
    },
    {
        dex: 25,
        isotope: '_08',
        shiny_released: true,
        family: 'Pikachu_08'
    },
    {
        dex: 26,
        isotope: '_08',
        shiny_released: true,
        family: 'Pikachu_08'
    },
    {
        dex: 25,
        isotope: '_10',
        shiny_released: true,
        family: 'Pikachu_10'
    },
    {
        dex: 25,
        shiny_released: true,
        fn: 'pm0025_00_pgo_fall2019',
        isotope: '_f19',
        family: 'Pikachu_f19'
    },
    {
        dex: 26,
        type: '_61',
        shiny_released: true,
        family: 'Pikachu_61'
    },
    {
        dex: 25,
        shiny_released: true,
        isotope: '_11',
        family: 'Pikachu_11'
    },
    {
        dex: 25,
        shiny_released: true,
        isotope: '_13',
        family: 'Pikachu_13'
    },
    {
        dex: 25,
        shiny_released: true,
        isotope: '_12',
        family: 'Pikachu_12'
    },
    {
        dex: 25,
        shiny_released: true,
        isotope: '_22',
        family: 'Pikachu_22'
    },
    {
        dex: 25,
        released_date: '2020/05/08',
        isotope: '_23',
        family: 'Pikachu_23'
    },
    {
        dex: 25,
        released_date: '2020/05/15',
        isotope: '_24',
        family: 'Pikachu_24'
    },
    {
        dex: 25,
        released_date: '2020/05/22',
        isotope: '_25',
        family: 'Pikachu_25'
    },
    {
        dex: 25,
        released_date: '2020/05/22',
        fn: 'pm0025_00_pgo_4thanniversary',
        isotope: '_4thY',
        family: 'Pikachu_4thY'
    },
    {
        dex: 25,
        isotope: '_27',
        shiny_released: true,
        family: 'Pikachu_27'
    },
    {
        dex: 26,
        isotope: '_27',
        shiny_released: true,
        family: 'Pikachu_27'
    },
    {
        dex: 25,
        shiny_released: true,
        fn: 'pm0025_00_pgo_movie2020',
        isotope: '_m2020',
        family: 'Pikachu_m2020'
    },
    {
        dex: 25,
        fn: 'pm0025_00_pgo_winter2020',
        released_date: '2020/12/22',
        isotope: '_winter2020',
        family: 'Pikachu_winter2020'
    },
    {
        dex: 25,
        isotope: '_28',
        released_date: '2020/12/29',
        family: 'Pikachu_28'
    },
    {
        dex: 26,
        isotope: '_28',
        released_date: '2020/12/29',
        family: 'Pikachu_28'
    },
    {
        dex: 25,
        type: '_16',
        released_date: '2020/12/29',
        family: 'Pikachu_16'
    },
    {
        dex: 25,
        released_date: '2021/07/06',
        fn: 'pm0025_00_pgo_5thanniversary',
        isotope: '_5thY',
        family: 'Pikachu_5thY'
    },
    {
        dex: 25,
        released_date: '2021/07/17',
        isotope: '_47',
        family: 'Pikachu_47'
    },
    {
        dex: 25,
        released_date: '2021/10/15',
        isotope: '_21Ha',
        aa_fn: 'pm25.cHALLOWEEN_2021_NOEVOLVE',
        cfn0:
            'https://cdn.jsdelivr.net/gh/PokeMiners/pogo_assets/Images/Pokemon%20-%20256x256/Addressable%20Assets/pm25.cHALLOWEEN_2021_NOEVOLVE.s.icon.png',
        cfn1:
            'https://cdn.jsdelivr.net/gh/PokeMiners/pogo_assets/Images/Pokemon%20-%20256x256/Addressable%20Assets/pm25.cHALLOWEEN_2021_NOEVOLVE.s.icon.png',

        family: 'Pikachu_21Ha'
    },
    {
        dex: 25,
        released_date: '2022/03/19',
        isotope: '_KARIYUSHI',
        aa_fn: 'pm25.fKARIYUSHI',
        cfn0:
            'https://cdn.jsdelivr.net/gh/PokeMiners/pogo_assets/Images/Pokemon%20-%20256x256/Addressable%20Assets/pm25.fKARIYUSHI.s.icon.png',
        cfn1:
            'https://cdn.jsdelivr.net/gh/PokeMiners/pogo_assets/Images/Pokemon%20-%20256x256/Addressable%20Assets/pm25.fKARIYUSHI.s.icon.png',
        family: 'Pikachu_KARIYUSHI'
    },
    {
        dex: 25,
        released_date: '2022/05/03',
        isotope: '_oki',
        aa_fn: 'pm25.fFLYING_OKINAWA',
        cfn0:
            'https://cdn.jsdelivr.net/gh/PokeMiners/pogo_assets/Images/Pokemon%20-%20256x256/Addressable%20Assets/pm25.fFLYING_OKINAWA.s.icon.png',
        cfn1:
            'https://cdn.jsdelivr.net/gh/PokeMiners/pogo_assets/Images/Pokemon%20-%20256x256/Addressable%20Assets/pm25.fFLYING_OKINAWA.s.icon.png',
        family: 'Pikachu_FLYING_OKINAWA'
    },
    {
        dex: 25,
        released_date: '2022/06/04',
        isotope: '_fest22_06',
        aa_fn: 'pm25.cGOFEST_2022_NOEVOLVE',
        cfn0:
            'https://cdn.jsdelivr.net/gh/PokeMiners/pogo_assets/Images/Pokemon%20-%20256x256/Addressable%20Assets/pm25.cGOFEST_2022_NOEVOLVE.s.icon.png',
        cfn1:
            'https://cdn.jsdelivr.net/gh/PokeMiners/pogo_assets/Images/Pokemon%20-%20256x256/Addressable%20Assets/pm25.cGOFEST_2022_NOEVOLVE.s.icon.png',
        family: 'Pikachu_GOFEST_2022'
    },
    {
        dex: 25,
        released_date: '2022/06/16',
        isotope: '_tcg',
        aa_fn: 'pm25.cTCG_2022_NOEVOLVE',
        cfn0:
            'https://cdn.jsdelivr.net/gh/PokeMiners/pogo_assets/Images/Pokemon%20-%20256x256/Addressable%20Assets/pm25.cTCG_2022_NOEVOLVE.s.icon.png',
        cfn1:
            'https://cdn.jsdelivr.net/gh/PokeMiners/pogo_assets/Images/Pokemon%20-%20256x256/Addressable%20Assets/pm25.cTCG_2022_NOEVOLVE.s.icon.png',
        family: 'Pikachu_TCG'
    },
    {
        dex: 25,
        isotope: '_22AN',
        released_date: '2022/07/06',
        aa_fn: 'pm25.cANNIVERSARY_2022_NOEVOLVE',
        family: 'Pikachu_22an',
        cfn1: 'https://cdn.jsdelivr.net/gh/PokeMiners/pogo_assets/Images/Pokemon%20-%20256x256/Addressable%20Assets/pm25.cANNIVERSARY_2022_NOEVOLVE.s.icon.png'
    },
    {
        dex: 25,
        isotope: '_22FEST',
        released_date: '2022/08/27',
        aa_fn: 'pm25.fGOFEST_2022',
        family: 'Pikachu_22FEST',
        cfn1: 'https://cdn.jsdelivr.net/gh/PokeMiners/pogo_assets/Images/Pokemon%20-%20256x256/Addressable%20Assets/pm25.fGOFEST_2022.s.icon.png'
    },
    {
        dex: 25,
        isotope: '_22fWCS',
        released_date: '2022/08/27',
        aa_fn: 'pm25.fWCS_2022',
        family: 'Pikachu_22fWCS',
        cfn1: 'https://cdn.jsdelivr.net/gh/PokeMiners/pogo_assets/Images/Pokemon%20-%20256x256/Addressable%20Assets/pm25.fWCS_2022.s.icon.png'
    },
    {
        dex: 27,
        family: 'Sandshrew',
        released_date: '2018/12/06'
    },
    {
        dex: 28,
        family: 'Sandshrew',
        released_date: '2018/12/06'
    },
    {
        dex: 27,
        shiny_released: true,
        type: '_61',
        family: 'Sandshrew_61'
    },
    {
        dex: 28,
        shiny_released: true,
        type: '_61',
        family: 'Sandshrew_61'
    },
    {
        dex: 29,
        shiny_released: true,
        family: 'Nidoran Female'
    },
    {
        dex: 30,
        shiny_released: true,
        family: 'Nidoran Female'
    },
    {
        dex: 31,
        shiny_released: true,
        family: 'Nidoran Female'
    },
    {
        dex: 32,
        shiny_released: true,
        family: 'Nidoran Male'
    },
    {
        dex: 33,
        shiny_released: true,
        family: 'Nidoran Male'
    },
    {
        dex: 33,
        isotope: '_11',
        shiny_released: true,
        family: 'Nidoran Male 11'
    },
    {
        dex: 34,
        shiny_released: true,
        family: 'Nidoran Male'
    },
    {
        dex: 35,
        family: 'Clefairy',
        released_date: '2019/02/13'
    },
    {
        dex: 36,
        family: 'Clefairy',
        released_date: '2019/02/13'
    },
    {
        dex: 37,
        released_date: '2020/10/09',
        family: 'Vulpix'
    },
    {
        dex: 38,
        released_date: '2020/10/09',
        family: 'Vulpix'
    },
    {
        dex: 37,
        shiny_released: true,
        type: '_61',
        family: 'Vulpix_61'
    },
    {
        dex: 38,
        shiny_released: true,
        type: '_61',
        family: 'Vulpix_61'
    },
    {
        dex: 39,
        family: 'Jigglypuff',
        released_date: '2019/04/16'
    },
    {
        dex: 40,
        family: 'Jigglypuff',
        released_date: '2019/04/16'
    },
    {
        dex: 41,
        family: 'Zubat',
        released_date: '2019/07/25'
    },
    {
        dex: 42,
        family: 'Zubat',
        released_date: '2019/07/25'
    },
    {
        dex: 43,
        family: 'Oddish',
        released_date: '2019/10/03'
    },
    {
        dex: 44,
        family: 'Oddish',
        released_date: '2019/10/03'
    },
    {
        dex: 45,
        family: 'Oddish',
        released_date: '2019/10/03'
    },
    {
        dex: 46,
        released_date: '2021/02/20',
        family: 'Paras'
    },
    {
        dex: 47,
        released_date: '2021/02/20',
        family: 'Paras'
    },
    {
        dex: 48,
        family: 'Venonat',
        released_date: '2020/05/01'
    },
    {
        dex: 49,
        family: 'Venonat',
        released_date: '2020/05/01'
    },
    {
        dex: 50,
        family: 'Diglett',
        released_date: '2019/04/29'
    },
    {
        dex: 51,
        family: 'Diglett',
        released_date: '2019/04/29'
    },
    {
        dex: 50,
        shiny_released: true,
        type: '_61',
        family: 'Diglett_61'
    },
    {
        dex: 51,
        shiny_released: true,
        type: '_61',
        family: 'Diglett_61'
    },
    {
        dex: 50,
        isotope: '_22n',
        aa_fn: 'pm50.cFALL_2022',
        released_date: '2022/09/27',
        style: '--t: -20%;',
        family: 'Diglett_22n',
        cfn1: 'https://cdn.jsdelivr.net/gh/PokeMiners/pogo_assets/Images/Pokemon%20-%20256x256/Addressable%20Assets/pm50.cFALL_2022.s.icon.png'
    },
    {
        dex: 51,
        isotope: '_22n',
        aa_fn: 'pm51.cFALL_2022',
        released_date: '2022/09/27',
        style: '--t: -20%;',
        family: 'Diglett_22n',
        cfn1: 'https://cdn.jsdelivr.net/gh/PokeMiners/pogo_assets/Images/Pokemon%20-%20256x256/Addressable%20Assets/pm51.cFALL_2022.s.icon.png'
    },
    {
        dex: 52,
        family: 'Meowth',
        released_date: '2019/11/07'
    },
    {
        dex: 53,
        family: 'Meowth',
        released_date: '2019/11/07'
    },
    {
        dex: 52,
        shiny_released: true,
        type: '_61',
        family: 'Meowth_61'
    },
    {
        dex: 53,
        shiny_released: true,
        type: '_61',
        family: 'Meowth_61'
    },
    {
        dex: 52,
        type: '_31',
        released_date: '2021/08/20',
        family: 'Meowth_31'
    },
    {
        dex: 863,
        type: '_31',
        released_date: '2021/08/20',
        family: 'Meowth_31'
    },
    {
        dex: 54,
        family: 'Psyduck',
        released_date: '2019/01/25'
    },
    {
        dex: 55,
        family: 'Psyduck',
        released_date: '2019/01/25'
    },
    {
        dex: 56,
        family: 'Mankey',
        released_date: '2019/03/05'
    },
    {
        dex: 57,
        family: 'Mankey',
        released_date: '2019/03/05'
    },
    {
        dex: 58,
        family: 'Growlithe',
        released_date: '2018/09/01'
    },
    {
        dex: 59,
        family: 'Growlithe',
        released_date: '2018/09/01'
    },
    {
        dex: 60,
        family: 'Poliwag',
        released_date: '2019/08/06'
    },
    {
        dex: 61,
        family: 'Poliwag',
        released_date: '2019/08/06'
    },
    {
        dex: 62,
        family: 'Poliwag',
        released_date: '2019/08/06'
    },
    {
        dex: 63,
        family: 'Abra',
        released_date: '2019/06/13'
    },
    {
        dex: 64,
        family: 'Abra',
        released_date: '2019/06/13'
    },
    {
        dex: 65,
        family: 'Abra',
        released_date: '2019/06/13'
    },
    {
        dex: 65,
        family: 'Abra',
        type: '_51',
        name_suffix: '(M)',
        released_date: '2022/09/06',
        cfn1: 'https://cdn.jsdelivr.net/gh/PokeMiners/pogo_assets/Images/Pokemon%20-%20256x256/pokemon_icon_065_51_shiny.png'
    },
    {
        dex: 66,
        family: 'Machop',
        released_date: '2019/03/05'
    },
    {
        dex: 67,
        family: 'Machop',
        released_date: '2019/03/05'
    },
    {
        dex: 68,
        family: 'Machop',
        released_date: '2019/03/05'
    },
    {
        dex: 69,
        released_date: '2020/07/16',
        family: 'Bellsprout'
    },
    {
        dex: 70,
        released_date: '2020/07/16',
        family: 'Bellsprout'
    },
    {
        dex: 71,
        released_date: '2020/07/16',
        family: 'Bellsprout'
    },
    {
        dex: 72,
        family: 'Tentacool',
        released_date: '2019/11/19'
    },
    {
        dex: 73,
        family: 'Tentacool',
        released_date: '2019/11/19'
    },
    {
        dex: 74,
        family: 'Geodude',
        released_date: '2018/09/13'
    },
    {
        dex: 75,
        family: 'Geodude',
        released_date: '2018/09/13'
    },
    {
        dex: 76,
        family: 'Geodude',
        released_date: '2018/09/13'
    },
    {
        dex: 74,
        shiny_released: true,
        type: '_61',
        family: 'Geodude_61'
    },
    {
        dex: 75,
        shiny_released: true,
        type: '_61',
        family: 'Geodude_61'
    },
    {
        dex: 76,
        shiny_released: true,
        type: '_61',
        family: 'Geodude_61'
    },
    {
        dex: 77,
        family: 'Ponyta',
        released_date: '2018/11/05'
    },
    {
        dex: 78,
        family: 'Ponyta',
        released_date: '2018/11/05'
    },
    {
        dex: 77,
        family: 'Ponyta_31',
        type: '_31',
        released_date: '2021/05/11'
    },
    {
        dex: 78,
        family: 'Ponyta_31',
        type: '_31',
        released_date: '2021/05/11'
    },
    {
        dex: 77,
        family: 'Ponyta_31_47',
        type: '_31',
        isotope: '_47',
        released_date: '2021/07/17'
    },
    {
        dex: 79,
        released_date: '2020/11/16',
        family: 'Slowpoke'
    },
    {
        dex: 80,
        released_date: '2020/11/16',
        family: 'Slowpoke'
    },
    {
        dex: 80,
        type: '_51',
        name_suffix: '(M)',
        released_date: '2021/06/08',
        family: 'Slowpoke'
    },
    {
        dex: 79,
        released_date: '2020/11/31',
        fn: 'pm0079_00_pgo_2020',
        isotope: '_2020',
        family: 'Slowpoke_2020'
    },
    {
        dex: 80,
        released_date: '2020/11/31',
        fn: 'pm0080_00_pgo_2021',
        isotope: '_2021',
        family: 'Slowpoke_2020'
    },
    {
        dex: 81,
        family: 'Magnemite',
        released_date: '2018/11/16'
    },
    {
        dex: 82,
        family: 'Magnemite',
        released_date: '2018/11/16'
    },
    {
        dex: 83,
        shiny_released: true,
        family: 'Farfetchd'
    },
    {
        dex: 83,
        type: '_31',
        released_date: '2021/08/20',
        family: 'Farfetchd_31'
    },
    {
        dex: 865,
        type: '_31',
        released_date: '2021/08/20',
        family: 'Farfetchd_31'
    },
    {
        dex: 84,
        released_date: '2020/09/21',
        family: 'Doduo'
    },
    {
        dex: 85,
        released_date: '2020/09/21',
        family: 'Doduo'
    },
    {
        dex: 86,
        family: 'Seel',
        released_date: '2019/05/01'
    },
    {
        dex: 87,
        family: 'Seel',
        released_date: '2019/05/01'
    },
    {
        dex: 88,
        family: 'Grimer',
        released_date: '2018/09/13'
    },
    {
        dex: 89,
        family: 'Grimer',
        released_date: '2018/09/13'
    },
    {
        dex: 88,
        shiny_released: true,
        type: '_61',
        family: 'Grimer_61'
    },
    {
        dex: 89,
        shiny_released: true,
        type: '_61',
        family: 'Grimer_61'
    },
    {
        dex: 90,
        family: 'Shellder',
        released_date: '2018/06/07'
    },
    {
        dex: 91,
        family: 'Shellder',
        released_date: '2018/06/07'
    },
    {
        dex: 92,
        cachebuster: '?v1',
        family: 'Gastly',
        released_date: '2018/11/03'
    },
    {
        dex: 93,
        family: 'Gastly',
        released_date: '2018/11/03'
    },
    {
        dex: 94,
        family: 'Gastly',
        released_date: '2018/11/03'
    },
    {
        dex: 94,
        type: '_51',
        name_suffix: '(M)',
        family: 'Gastly',
        released_date: '2018/11/03'
    },
    {
        dex: 94,
        isotope: '_11',
        shiny_released: true,
        family: 'Gastly_11'
    },
    {
        dex: 94,
        type: '_26',
        shiny_released: true,
        family: 'Gastly_26'
    },
    {
        dex: 95,
        family: 'Onix',
        released_date: '2019/06/04'
    },
    {
        dex: 96,
        family: 'Drowzee',
        released_date: '2018/10/05'
    },
    {
        dex: 97,
        family: 'Drowzee',
        released_date: '2018/10/05'
    },
    {
        dex: 98,
        family: 'Krabby',
        released_date: '2018/10/01'
    },
    {
        dex: 99,
        family: 'Krabby',
        released_date: '2018/10/01'
    },
    {
        dex: 100,
        family: 'Voltorb',
        released_date: '2020/05/08'
    },
    {
        dex: 101,
        family: 'Voltorb',
        released_date: '2020/05/08'
    },
    {
        dex: 102,
        family: 'Exeggcute',
        released_date: '2020/04/08'
    },
    {
        dex: 103,
        family: 'Exeggcute',
        released_date: '2020/04/08'
    },
    {
        dex: 103,
        type: '_61',
        family: 'Exeggcute',
        released_date: '2020/04/08'
    },
    {
        dex: 104,
        family: 'Cubone',
        released_date: '2018/11/05'
    },
    {
        dex: 105,
        family: 'Cubone',
        released_date: '2018/11/05'
    },
    {
        dex: 105,
        type: '_61',
        family: 'Cubone',
        released_date: '2018/11/05'
    },
    {
        dex: 106,
        released_date: '2021/02/20',
        family: 'Tyrogue'
    },
    {
        dex: 107,
        released_date: '2021/02/20',
        family: 'Tyrogue'
    },
    {
        dex: 108,
        family: 'Lickitung',
        released_date: '2020/02/15'
    },
    {
        dex: 109,
        family: 'Koffing',
        released_date: '2019/07/25'
    },
    {
        dex: 110,
        family: 'Koffing',
        released_date: '2019/07/25'
    },
    {
        dex: 110,
        type: '_31',
        released_date: '2021/08/20',
        family: 'Koffing_31'
    },
    {
        dex: 111,
        family: 'Rhyhorn',
        released_date: '2020/02/22'
    },
    {
        dex: 112,
        family: 'Rhyhorn',
        released_date: '2020/02/22'
    },
    {
        dex: 113,
        family: 'Chansey',
        released_date: '2020/02/13'
    },
    {
        dex: 113,
        family: 'Chansey_07',
        isotope: '_07',
        released_date: '2021/04/04'
    },
    {
        dex: 114,
        family: 'Tangela',
        released_date: '2020/07/24'
    },
    {
        dex: 115,
        shiny_released: true,
        family: 'Kangaskhan'
    },
    {
        dex: 115,
        type: '_51',
        aa_fn: 'pm115.fMEGA',
        name_suffix: '(M)',
        released_date: '2022/04/29',
        family: 'Kangaskhan'
    },
    {
        dex: 116,
        family: 'Horsea',
        released_date: '2019/06/13'
    },
    {
        dex: 117,
        family: 'Horsea',
        released_date: '2019/06/13'
    },
    {
        dex: 118,
        released_date: '2020/11/23',
        family: 'Goldeen'
    },
    {
        dex: 119,
        released_date: '2020/11/23',
        family: 'Goldeen'
    },
    {
        dex: 120,
        released_date: '2020/08/07',
        family: 'Staryu'
    },
    {
        dex: 121,
        released_date: '2020/08/07',
        family: 'Staryu'
    },
    {
        dex: 122,
        shiny_released: true,
        family: 'Mr Mime'
    },
    {
        dex: 123,
        family: 'Scyther',
        released_date: '2019/04/02'
    },
    {
        dex: 124,
        family: 'Jynx',
        released_date: '2019/02/13'
    },
    {
        dex: 125,
        family: 'Electabuzz',
        released_date: '2018/11/15'
    },
    {
        dex: 126,
        family: 'Magmar',
        released_date: '2018/03/22'
    },
    {
        dex: 127,
        shiny_released: true,
        family: 'Pinsir'
    },
    {
        dex: 128,
        shiny_released: true,
        family: 'Tauros'
    },
    {
        dex: 129,
        family: 'Magikarp',
        released_date: '2017/03/22'
    },
    {
        dex: 130,
        family: 'Magikarp',
        released_date: '2017/03/22'
    },
    {
        dex: 130,
        type: '_51',
        name_suffix: '(M)',
        family: 'Magikarp',
        released_date: '2017/03/22'
    },
    {
        dex: 131,
        shiny_released: true,
        family: 'Lapras'
    },
    {
        dex: 131,
        shiny_released: true,
        aa_fn: 'pm131.fCOSTUME_2020',
        isotope: '_2020',
        released_date: '2022/05/12',
        family: 'Lapras',
        cfn0:
            'https://cdn.jsdelivr.net/gh/PokeMiners/pogo_assets/Images/Pokemon%20-%20256x256/Addressable%20Assets/pm131.fCOSTUME_2020.s.icon.png',
        cfn1:
            'https://cdn.jsdelivr.net/gh/PokeMiners/pogo_assets/Images/Pokemon%20-%20256x256/Addressable%20Assets/pm131.fCOSTUME_2020.s.icon.png'
    },
    {
        dex: 132,
        released_date: '2021/02/20',
        family: 'Ditto'
    },
    {
        dex: 133,
        family: 'Eevee',
        released_date: '2018/08/11'
    },
    {
        dex: 133,
        shiny_released: true,
        isotope: '_07',
        family: 'Eevee_07'
    },
    {
        dex: 133,
        shiny_released: true,
        isotope: '_11',
        family: 'Eevee_11'
    },
    {
        dex: 134,
        family: 'Eevee',
        released_date: '2018/08/11'
    },
    {
        dex: 134,
        shiny_released: true,
        isotope: '_07',
        family: 'Eevee_07'
    },
    {
        dex: 135,
        family: 'Eevee',
        released_date: '2018/08/11'
    },
    {
        dex: 135,
        shiny_released: true,
        isotope: '_07',
        family: 'Eevee_07'
    },
    {
        dex: 136,
        family: 'Eevee',
        released_date: '2018/08/11'
    },
    {
        dex: 136,
        shiny_released: true,
        isotope: '_07',
        family: 'Eevee_07'
    },
    {
        dex: 137,
        released_date: '2020/09/19',
        family: 'Porygon'
    },
    {
        dex: 138,
        family: 'Omanyte',
        released_date: '2018/05/24'
    },
    {
        dex: 139,
        family: 'Omanyte',
        released_date: '2018/05/24'
    },
    {
        dex: 140,
        family: 'Kabuto',
        released_date: '2018/05/24'
    },
    {
        dex: 141,
        family: 'Kabuto',
        released_date: '2018/05/24'
    },
    {
        dex: 142,
        shiny_released: true,
        family: 'Aerodactyl'
    },
    {
        dex: 142,
        shiny_released: true,
        type: '_51',
        aa_fn: 'pm142.fMEGA',
        name_suffix: '(M)',
        family: 'Aerodactyl'
    },
    {
        dex: 143,
        released_date: '2021/02/20',
        family: 'Snorlax'
    },
    {
        dex: 143,
        released_date: '2022/07/16',
        isotope: '_22',
        aa_fn: 'pm143.cGOFEST_2022_NOEVOLVE',
        family: 'Snorlax',
        cfn1: 'https://cdn.jsdelivr.net/gh/PokeMiners/pogo_assets/Images/Pokemon%20-%20256x256/Addressable%20Assets/pm143.cGOFEST_2022_NOEVOLVE.s.icon.png'
    },
    {
        dex: 144,
        shiny_released: true,
        family: 'Articuno'
    },
    {
        dex: 145,
        shiny_released: true,
        family: 'Zapdos'
    },
    {
        dex: 146,
        shiny_released: true,
        family: 'Moltres'
    },
    {
        dex: 147,
        family: 'Dratini',
        released_date: '2018/02/24'
    },
    {
        dex: 148,
        family: 'Dratini',
        released_date: '2018/02/24'
    },
    {
        dex: 149,
        family: 'Dratini',
        released_date: '2018/02/24'
    },
    {
        dex: 150,
        shiny_released: true,
        family: 'Mewtwo'
    },
    {
        dex: 151,
        released_date: '2021/02/20',
        family: 'Mew'
    },
    {
        dex: 152,
        family: 'Chikorita',
        released_date: '2018/09/22'
    },
    {
        dex: 153,
        family: 'Chikorita',
        released_date: '2018/09/22'
    },
    {
        dex: 154,
        family: 'Chikorita',
        released_date: '2018/09/22'
    },
    {
        dex: 155,
        family: 'Cyndaquil',
        released_date: '2018/11/10'
    },
    {
        dex: 156,
        family: 'Cyndaquil',
        released_date: '2018/11/10'
    },
    {
        dex: 157,
        family: 'Cyndaquil',
        released_date: '2018/11/10'
    },
    {
        dex: 158,
        family: 'Totodile',
        released_date: '2019/01/12'
    },
    {
        dex: 159,
        family: 'Totodile',
        released_date: '2019/01/12'
    },
    {
        dex: 160,
        family: 'Totodile',
        released_date: '2019/01/12'
    },
    {
        dex: 161,
        family: 'Sentret',
        released_date: '2019/09/02'
    },
    {
        dex: 162,
        family: 'Sentret',
        released_date: '2019/09/02'
    },
    {
        dex: 163,
        family: 'Hoothoot',
        released_date: '2022/01/01'
    },
    {
        dex: 163,
        family: 'Hoothoot_2022',
        aa_fn: 'pm163.cJAN_2022_NOEVOLVE',
        cfn0:
            'https://cdn.jsdelivr.net/gh/PokeMiners/pogo_assets/Images/Pokemon%20-%20256x256/Addressable%20Assets/pm163.cJAN_2022_NOEVOLVE.s.icon.png',
        cfn1:
            'https://cdn.jsdelivr.net/gh/PokeMiners/pogo_assets/Images/Pokemon%20-%20256x256/Addressable%20Assets/pm163.cJAN_2022_NOEVOLVE.s.icon.png',

        isotope: '_202201',
        released_date: '2022/01/01'
    },
    {
        dex: 164,
        family: 'Hoothoot',
        released_date: '2022/01/01'
    },
    {
        dex: 165,
        released_date: '2020/09/10',
        family: 'Ledyba'
    },
    {
        dex: 166,
        released_date: '2020/09/10',
        family: 'Ledyba'
    },
    {
        dex: 167,
        released_date: '2021/10/15',
        family: 'Spinarak'
    },
    {
        dex: 168,
        released_date: '2021/10/15',
        family: 'Spinarak'
    },
    {
        dex: 169,
        family: 'Zubat',
        released_date: '2019/07/25'
    },
    {
        dex: 170,
        family: 'Chinchou',
        released_date: '2020/04/17'
    },
    {
        dex: 171,
        family: 'Chinchou',
        released_date: '2020/04/17'
    },
    {
        dex: 172,
        order: -1,
        family: 'Pikachu',
        released_date: '2017/08/15'
    },
    {
        dex: 172,
        isotope: '_02',
        shiny_released: true,
        order: -1,
        family: 'Pikachu_02'
    },
    {
        dex: 172,
        isotope: '_03',
        shiny_released: true,
        order: -1,
        family: 'Pikachu_03'
    },
    {
        dex: 172,
        isotope: '_04',
        shiny_released: true,
        order: -1,
        family: 'Pikachu_04'
    },
    {
        dex: 172,
        isotope: '_05',
        shiny_released: true,
        order: -1,
        family: 'Pikachu_05'
    },
    {
        dex: 172,
        isotope: '_08',
        shiny_released: true,
        order: -1,
        family: 'Pikachu_08'
    },
    {
        dex: 172,
        isotope: '_01',
        shiny_released: true,
        order: -1,
        family: 'Pikachu_01'
    },
    {
        dex: 172,
        isotope: '_07',
        shiny_released: true,
        order: -1,
        family: 'Pikachu_07'
    },
    {
        dex: 172,
        order: -1,
        isotope: '_28',
        released_date: '2020/12/29',
        family: 'Pikachu_28'
    },
    {
        dex: 173,
        order: -1,
        family: 'Clefairy',
        released_date: '2019/02/13'
    },
    {
        dex: 174,
        order: -1,
        family: 'Jigglypuff',
        released_date: '2019/04/16'
    },
    {
        dex: 175,
        family: 'Togepi',
        released_date: '2018/03/22'
    },
    {
        dex: 175,
        isotope: '_12',
        family: 'Togepi',
        released_date: '2018/03/22'
    },
    {
        dex: 176,
        family: 'Togepi',
        released_date: '2018/03/22'
    },
    {
        dex: 176,
        isotope: '_12',
        family: 'Togepi',
        aa_fn: 'pm176.cAPRIL_2020_NOEVOLVE',
        released_date: '2022/04/12',
        cfn0:
            'https://cdn.jsdelivr.net/gh/PokeMiners/pogo_assets/Images/Pokemon%20-%20256x256/Addressable%20Assets/pm176.cAPRIL_2020_NOEVOLVE.s.icon.png',
        cfn1:
            'https://cdn.jsdelivr.net/gh/PokeMiners/pogo_assets/Images/Pokemon%20-%20256x256/Addressable%20Assets/pm176.cAPRIL_2020_NOEVOLVE.s.icon.png'
    },
    {
        dex: 177,
        family: 'Natu',
        released_date: '2018/08/20'
    },
    {
        dex: 178,
        family: 'Natu',
        released_date: '2018/08/20'
    },
    {
        dex: 179,
        family: 'Mareep',
        released_date: '2018/04/15'
    },
    {
        dex: 180,
        family: 'Mareep',
        released_date: '2018/04/15'
    },
    {
        dex: 181,
        family: 'Mareep',
        released_date: '2018/04/15'
    },
    {
        dex: 181,
        type: '_51',
        name_suffix: '(M)',
        family: 'Mareep',
        released_date: '2018/04/15'
    },
    {
        dex: 182,
        family: 'Oddish',
        released_date: '2019/10/03'
    },
    {
        dex: 183,
        family: 'Marill',
        released_date: '2018/12/18'
    },
    {
        dex: 184,
        family: 'Marill',
        released_date: '2018/12/18'
    },
    {
        dex: 185,
        shiny_released: true,
        family: 'Sudowoodo'
    },
    {
        dex: 186,
        family: 'Poliwag',
        released_date: '2019/08/06'
    },
    {
        dex: 187,
        family: 'Hoppip',
        released_date: '2022/02/12'
    },
    {
        dex: 188,
        family: 'Hoppip',
        released_date: '2022/02/12'
    },
    {
        dex: 189,
        family: 'Hoppip',
        released_date: '2022/02/12'
    },
    {
        dex: 190,
        family: 'Aipom',
        released_date: '2019/05/07'
    },
    {
        dex: 191,
        family: 'Sunkern',
        released_date: '2018/08/20'
    },
    {
        dex: 192,
        family: 'Sunkern',
        released_date: '2018/08/20'
    },
    {
        dex: 193,
        family: 'Yanma',
        released_date: '2019/09/20'
    },
    {
        dex: 194,
        released_date: '2020/12/11',
        family: 'Wooper'
    },
    {
        dex: 195,
        released_date: '2020/12/11',
        family: 'Wooper'
    },
    {
        dex: 196,
        family: 'Eevee',
        released_date: '2018/08/11'
    },
    {
        dex: 196,
        shiny_released: true,
        isotope: '_07',
        family: 'Eevee_07'
    },
    {
        dex: 197,
        family: 'Eevee',
        released_date: '2018/08/11'
    },
    {
        dex: 197,
        shiny_released: true,
        isotope: '_07',
        family: 'Eevee_07'
    },
    {
        dex: 198,
        shiny_released: true,
        family: 'Murkrow'
    },
    {
        dex: 199,
        released_date: '2020/11/16',
        family: 'Slowpoke'
    },
    {
        dex: 199,
        released_date: '2022/01/01',
        aa_fn: 'pm199.f2022',
        isotope: '_f2022',
        family: 'Slowpoke_2020',
        cfn0:
            'https://cdn.jsdelivr.net/gh/PokeMiners/pogo_assets/Images/Pokemon%20-%20256x256/Addressable%20Assets/pm199.f2022.s.icon.png',
        cfn1:
            'https://cdn.jsdelivr.net/gh/PokeMiners/pogo_assets/Images/Pokemon%20-%20256x256/Addressable%20Assets/pm199.f2022.s.icon.png'
    },
    {
        dex: 200,
        family: 'Misdreavus',
        released_date: '2018/12/01'
    },
    {
        dex: 201,
        family: 'Unown'
    },
    {
        dex: 201,
        type: '_11',
        shiny_released: true,
        family: 'Unown',
        name_suffix: '(A)'
    },
    {
        dex: 201,
        type: '_12',
        shiny_released: true,
        family: 'Unown',
        name_suffix: '(B)'
    },
    {
        dex: 201,
        type: '_13',
        shiny_released: true,
        family: 'Unown',
        name_suffix: '(C)'
    },
    {
        dex: 201,
        type: '_14',
        family: 'Unown',
        shiny_released: true,
        name_suffix: '(D)'
    },
    {
        dex: 201,
        type: '_15',
        released_date: '2022/05/13',
        family: 'Unown',
        name_suffix: '(E)'
    },
    {
        dex: 201,
        type: '_16',
        released_date: '2021/07/17',
        family: 'Unown',
        name_suffix: '(F)'
    },
    {
        dex: 201,
        type: '_17',
        shiny_released: true,
        family: 'Unown',
        name_suffix: '(G)'
    },
    {
        dex: 201,
        type: '_18',
        family: 'Unown',
        name_suffix: '(H)'
    },
    {
        dex: 201,
        type: '_19',
        released_date: '2022/05/13',
        family: 'Unown',
        name_suffix: '(I)'
    },
    {
        dex: 201,
        type: '_20',
        family: 'Unown',
        released_date: '2022/02/26',
        name_suffix: '(J)'
    },
    {
        dex: 201,
        type: '_21',
        family: 'Unown',
        name_suffix: '(K)'
    },
    {
        dex: 201,
        type: '_22',
        shiny_released: true,
        family: 'Unown',
        name_suffix: '(L)'
    },
    {
        dex: 201,
        type: '_23',
        family: 'Unown',
        name_suffix: '(M)'
    },
    {
        dex: 201,
        type: '_24',
        released_date: '2022/08/27',
        family: 'Unown',
        name_suffix: '(N)'
    },
    {
        dex: 201,
        type: '_25',
        shiny_released: true,
        family: 'Unown',
        name_suffix: '(O)'
    },
    {
        dex: 201,
        type: '_26',
        family: 'Unown',
        shiny_released: true,
        name_suffix: '(P)'
    },
    {
        dex: 201,
        type: '_27',
        family: 'Unown',
        name_suffix: '(Q)'
    },
    {
        dex: 201,
        type: '_28',
        shiny_released: true,
        family: 'Unown',
        name_suffix: '(R)'
    },
    {
        dex: 201,
        type: '_29',
        released_date: '2022/05/13',
        family: 'Unown',
        name_suffix: '(S)'
    },
    {
        dex: 201,
        type: '_30',
        shiny_released: true,
        family: 'Unown',
        name_suffix: '(T)'
    },
    {
        dex: 201,
        type: '_31',
        shiny_released: true,
        family: 'Unown',
        name_suffix: '(U)'
    },
    {
        dex: 201,
        type: '_32',
        released_date: '2022/05/13',
        family: 'Unown',
        name_suffix: '(V)'
    },
    {
        dex: 201,
        type: '_33',
        family: 'Unown',
        name_suffix: '(W)'
    },
    {
        dex: 201,
        type: '_34',
        released_date: '2022/08/27',
        family: 'Unown',
        name_suffix: '(X)'
    },
    {
        dex: 201,
        type: '_35',
        family: 'Unown',
        released_date: '2022/09/24',
        name_suffix: '(Y)'
    },
    {
        dex: 201,
        type: '_36',
        family: 'Unown',
        name_suffix: '(Z)'
    },
    {
        dex: 201,
        type: '_37',
        family: 'Unown',
        name_suffix: '(!)'
    },
    {
        dex: 201,
        type: '_38',
        family: 'Unown',
        name_suffix: '(?)'
    },
    {
        dex: 202,
        family: 'Wobbuffet',
        released_date: '2018/03/22'
    },
    {
        dex: 202,
        family: 'Wobbuffet',
        type: '_01',
        released_date: '2018/03/22'
    },
    {
        dex: 202,
        isotope: '_11',
        family: 'Wobbuffet',
        released_date: '2018/03/22'
    },
    {
        dex: 203,
        family: 'Girafarig',
        released_date: '2022/02/26'
    },
    {
        dex: 204,
        family: 'Pineco',
        released_date: '2018/08/20'
    },
    {
        dex: 205,
        family: 'Pineco',
        released_date: '2018/08/20'
    },
    {
        dex: 206,
        shiny_released: true,
        family: 'Dunsparce'
    },
    {
        dex: 207,
        family: 'Gligar',
        released_date: '2019/09/02'
    },
    {
        dex: 208,
        family: 'Onix',
        released_date: '2019/06/04'
    },
    {
        dex: 208,
        family: 'Onix',
        type: '_51',
        aa_fn: 'pm208.fMEGA',
        name_suffix: '(M)',
        released_date: '2021/12/01',
        cfn1: 'https://cdn.jsdelivr.net/gh/PokeMiners/pogo_assets/Images/Pokemon%20-%20256x256/Addressable%20Assets/pm208.fMEGA.s.icon.png'
    },
    {
        dex: 209,
        family: 'Snubbull',
        released_date: '2018/08/01'
    },
    {
        dex: 210,
        family: 'Snubbull',
        released_date: '2018/08/01'
    },
    {
        dex: 211,
        shiny_released: true,
        family: 'Qwilfish'
    },
    {
        dex: 212,
        family: 'Scyther',
        released_date: '2019/04/02'
    },
    {
        dex: 212,
        family: 'Scyther',
        aa_fn: 'pm212.fMEGA',
        type: '_51',
        name_suffix: '(M)',
        released_date: '2022/08/10',
        cfn1: 'https://cdn.jsdelivr.net/gh/PokeMiners/pogo_assets/Images/Pokemon%20-%20256x256/Addressable%20Assets/pm212.fMEGA.s.icon.png'
    },
    {
        dex: 213,
        shiny_released: true,
        family: 'Shuckle'
    },
    {
        dex: 214,
        family: 'Heracross',
        released_date: '2021/08/06'
    },
    {
        dex: 214,
        family: 'Heracross',
        type: '_01',
        released_date: '2021/08/06'
    },
    {
        dex: 215,
        family: 'Sneasel',
        released_date: '2019/07/25'
    },
    {
        dex: 215,
        family: 'Sneasel_2021_FASHION',
        aa_fn: 'pm215.cFASHION_2021_NOEVOLVE',
        cfn0:
            'https://raw.githubusercontent.com/PokeMiners/pogo_assets/master/Images/Pokemon%20-%20256x256/Addressable%20Assets/pm215.cFASHION_2021_NOEVOLVE.icon.png',
        cfn1:
            'https://raw.githubusercontent.com/PokeMiners/pogo_assets/master/Images/Pokemon%20-%20256x256/Addressable%20Assets/pm215.cFASHION_2021_NOEVOLVE.s.icon.png',

        isotope: '_2021_FASHION',
        released_date: '2021/09/21'
    },
    {
        dex: 216,
        family: 'Teddiursa',
        released_date: '2020/03/26'
    },
    {
        dex: 217,
        family: 'Teddiursa',
        released_date: '2020/03/26'
    },
    {
        dex: 218,
        family: 'Slugma',
        released_date: '2021/01/07'
    },
    {
        dex: 219,
        family: 'Slugma',
        released_date: '2021/01/07'
    },
    {
        dex: 220,
        family: 'Swinub',
        released_date: '2019/02/16'
    },
    {
        dex: 221,
        family: 'Swinub',
        released_date: '2019/02/16'
    },
    {
        dex: 222,
        family: 'Corsola',
        released_date: '2022/02/26'
    },
    {
        dex: 223,
        family: 'Remoraid',
        released_date: '2022/02/26'
    },
    {
        dex: 224,
        family: 'Remoraid',
        released_date: '2022/02/26'
    },
    {
        dex: 225,
        shiny_released: true,
        family: 'Delibird'
    },
    {
        dex: 225,
        released_date: '2020/12/22',
        isotope: '_winter2020',
        fn: 'pm0225_00_pgo_winter2020',
        family: 'Delibird'
    },
    {
        dex: 226,
        family: 'Mantine',
        released_date: '2022/02/26'
    },
    {
        dex: 227,
        shiny_released: true,
        family: 'Skarmory'
    },
    {
        dex: 228,
        family: 'Houndour',
        released_date: '2018/08/01'
    },
    {
        dex: 229,
        family: 'Houndour',
        released_date: '2018/08/01'
    },
    {
        dex: 229,
        type: '_51',
        name_suffix: '(M)',
        family: 'Houndour',
        released_date: '2018/08/01'
    },
    {
        dex: 230,
        family: 'Horsea',
        released_date: '2019/06/13'
    },
    {
        dex: 231,
        family: 'Phanpy',
        released_date: '2022/02/26'
    },
    {
        dex: 232,
        family: 'Phanpy',
        released_date: '2022/02/26'
    },
    {
        dex: 233,
        released_date: '2020/09/19',
        family: 'Porygon'
    },
    {
        dex: 234,
        shiny_released: true,
        family: 'Stantler'
    },
    {
        dex: 234,
        shiny_released: true,
        isotope: '_08',
        family: 'Stantler'
    },
    {
        dex: 235,
        released_date: '2021/04/29',
        family: 'Smeargle'
    },
    {
        dex: 236,
        order: -1,
        family: 'Tyrogue',
        released_date: '2022/02/26'
    },
    {
        dex: 237,
        family: 'Tyrogue',
        released_date: '2022/02/26'
    },
    {
        dex: 238,
        order: -1,
        family: 'Jynx',
        released_date: '2019/02/13'
    },
    {
        dex: 238,
        order: -1,
        isotope: '_16',
        family: 'Jynx',
        released_date: '2019/02/13'
    },
    {
        dex: 239,
        order: -1,
        family: 'Electabuzz',
        released_date: '2018/11/15'
    },
    {
        dex: 240,
        order: -1,
        family: 'Magmar',
        released_date: '2018/03/22'
    },
    {
        dex: 241,
        released_date: '2021/01/26',
        family: 'Miltank'
    },
    {
        dex: 242,
        family: 'Chansey',
        released_date: '2020/02/13'
    },
    {
        dex: 242,
        family: 'Chansey_07',
        isotope: '_07',
        released_date: '2021/04/04'
    },
    {
        dex: 243,
        shiny_released: true,
        family: 'Raikou'
    },
    {
        dex: 244,
        shiny_released: true,
        family: 'Entei'
    },
    {
        dex: 245,
        shiny_released: true,
        family: 'Suicune'
    },
    {
        dex: 246,
        family: 'Larvitar',
        released_date: '2018/06/16'
    },
    {
        dex: 247,
        family: 'Larvitar',
        released_date: '2018/06/16'
    },
    {
        dex: 248,
        family: 'Larvitar',
        released_date: '2018/06/16'
    },
    {
        dex: 249,
        shiny_released: true,
        family: 'Lugia'
    },
    {
        dex: 250,
        shiny_released: true,
        family: 'Ho Oh'
    },
    {
        dex: 251,
        released_date: '2020/12/14',
        family: 'Celebi'
    },
    {
        dex: 252,
        family: 'Treecko',
        released_date: '2019/03/23'
    },
    {
        dex: 253,
        family: 'Treecko',
        released_date: '2019/03/23'
    },
    {
        dex: 254,
        family: 'Treecko',
        released_date: '2019/03/23'
    },
    {
        dex: 255,
        family: 'Torchic',
        released_date: '2019/05/19'
    },
    {
        dex: 256,
        family: 'Torchic',
        released_date: '2019/05/19'
    },
    {
        dex: 257,
        family: 'Torchic',
        released_date: '2019/05/19'
    },
    {
        dex: 258,
        family: 'Mudkip',
        released_date: '2019/07/21'
    },
    {
        dex: 259,
        family: 'Mudkip',
        released_date: '2019/07/21'
    },
    {
        dex: 260,
        family: 'Mudkip',
        released_date: '2019/07/21'
    },
    {
        dex: 261,
        family: 'Poochyena',
        released_date: '2018/02/15'
    },
    {
        dex: 262,
        family: 'Poochyena',
        released_date: '2018/02/15'
    },
    {
        dex: 263,
        family: 'Zigzagoon',
        released_date: '2019/01/15'
    },
    {
        dex: 264,
        family: 'Zigzagoon',
        released_date: '2019/01/15'
    },
    {
        dex: 263,
        type: '_31',
        family: 'Zigzagoon_31',
        released_date: '2020/05/25'
    },
    {
        dex: 264,
        type: '_31',
        family: 'Zigzagoon_31',
        released_date: '2020/05/25'
    },
    {
        dex: 263,
        type: '_31',
        isotope: '_47',
        family: 'Zigzagoon_31_47',
        released_date: '2021/07/17'
    },
    {
        dex: 265,
        family: 'Wurmple',
        released_date: '2020/01/02'
    },
    {
        dex: 265,
        isotope: '_11',
        family: 'Wurmple',
        released_date: '2020/01/02'
    },
    {
        dex: 266,
        family: 'Wurmple',
        released_date: '2020/01/02'
    },
    {
        dex: 267,
        family: 'Wurmple',
        released_date: '2020/01/02'
    },
    {
        dex: 268,
        family: 'Wurmple',
        released_date: '2020/01/02'
    },
    {
        dex: 269,
        family: 'Wurmple',
        released_date: '2020/01/02'
    },
    {
        dex: 270,
        family: 'Lotad',
        released_date: '2019/03/29'
    },
    {
        dex: 271,
        family: 'Lotad',
        released_date: '2019/03/29'
    },
    {
        dex: 272,
        family: 'Lotad',
        released_date: '2019/03/29'
    },
    {
        dex: 273,
        released_date: '2020/05/23',
        family: 'Seedot'
    },
    {
        dex: 274,
        released_date: '2020/05/23',
        family: 'Seedot'
    },
    {
        dex: 275,
        released_date: '2020/05/23',
        family: 'Seedot'
    },
    {
        dex: 276,
        family: 'Taillow',
        released_date: '2019/01/15'
    },
    {
        dex: 277,
        family: 'Taillow',
        released_date: '2019/01/15'
    },
    {
        dex: 278,
        family: 'Wingull',
        released_date: '2018/08/29'
    },
    {
        dex: 279,
        family: 'Wingull',
        released_date: '2018/08/29'
    },
    {
        dex: 280,
        family: 'Ralts',
        released_date: '2019/08/03'
    },
    {
        dex: 281,
        family: 'Ralts',
        released_date: '2019/08/03'
    },
    {
        dex: 281,
        isotope: '_16',
        family: 'Ralts',
        released_date: '2019/08/03'
    },
    {
        dex: 282,
        family: 'Ralts',
        released_date: '2019/08/03'
    },
    {
        dex: 283,
        family: 'Surskit'
    },
    {
        dex: 284,
        family: 'Surskit'
    },
    {
        dex: 285,
        family: 'Shroomish',
        released_date: '2022/06/04'
    },
    {
        dex: 286,
        family: 'Shroomish',
        released_date: '2022/06/04'
    },
    {
        dex: 287,
        family: 'Slakoth',
        released_date: '2019/06/08'
    },
    {
        dex: 288,
        family: 'Slakoth',
        released_date: '2019/06/08'
    },
    {
        dex: 289,
        family: 'Slakoth',
        released_date: '2019/06/08'
    },
    {
        dex: 290,
        family: 'Nincada',
        released_date: '2020/03/19'
    },
    {
        dex: 291,
        family: 'Nincada',
        released_date: '2020/03/19'
    },
    {
        dex: 292,
        released_date: '2022/10/01',
        family: 'Nincada'
    },
    {
        dex: 293,
        family: 'Whismur',
        released_date: '2021/07/17'
    },
    {
        dex: 294,
        family: 'Whismur',
        released_date: '2021/07/17'
    },
    {
        dex: 295,
        family: 'Whismur',
        released_date: '2021/07/17'
    },
    {
        dex: 296,
        family: 'Makuhita',
        released_date: '2018/05/01'
    },
    {
        dex: 297,
        family: 'Makuhita',
        released_date: '2018/05/01'
    },
    {
        dex: 298,
        order: -1,
        family: 'Marill',
        released_date: '2018/12/18'
    },
    {
        dex: 299,
        family: 'Nosepass',
        released_date: '2021/03/09'
    },
    {
        dex: 300,
        released_date: '2020/05/15',
        family: 'Skitty'
    },
    {
        dex: 301,
        released_date: '2020/05/15',
        family: 'Skitty'
    },
    {
        dex: 302,
        shiny_released: true,
        family: 'Sableye'
    },
    {
        dex: 302,
        fn: 'pm0302_00_pgo_fall2020',
        isotope: '_f20',
        shiny_released: true,
        family: 'Sableye'
    },
    {
        dex: 303,
        shiny_released: true,
        family: 'Mawile'
    },
    {
        dex: 304,
        family: 'Aron',
        released_date: '2018/01/23'
    },
    {
        dex: 305,
        family: 'Aron',
        released_date: '2018/01/23'
    },
    {
        dex: 306,
        family: 'Aron',
        released_date: '2018/01/23'
    },
    {
        dex: 306,
        family: 'Aron',
        type: '_51',
        name_suffix: '(M)',
        aa_fn: 'pm306.fMEGA',
        released_date: '2022/09/16',
        cfn1: 'https://cdn.jsdelivr.net/gh/PokeMiners/pogo_assets/Images/Pokemon%20-%20256x256/Addressable%20Assets/pm306.fMEGA.s.icon.png'
    },
    {
        dex: 307,
        family: 'Meditite',
        released_date: '2018/05/01'
    },
    {
        dex: 308,
        family: 'Meditite',
        released_date: '2018/05/01'
    },
    {
        dex: 309,
        family: 'Electrike',
        released_date: '2019/09/01'
    },
    {
        dex: 310,
        family: 'Electrike',
        released_date: '2019/09/01'
    },
    {
        dex: 310,
        type: '_51',
        name_suffix: '(M)',
        family: 'Electrike',
        released_date: '2021/03/16'
    },
    {
        dex: 311,
        shiny_released: true,
        family: 'Plusle'
    },
    {
        dex: 312,
        shiny_released: true,
        family: 'Minun'
    },
    {
        dex: 313,
        shiny_released: true,
        family: 'Volbeat'
    },
    {
        dex: 314,
        shiny_released: true,
        family: 'Illumise'
    },
    {
        dex: 315,
        shiny_released: true,
        family: 'Roselia'
    },
    {
        dex: 316,
        family: 'Gulpin'
    },
    {
        dex: 317,
        family: 'Gulpin'
    },
    {
        dex: 318,
        family: 'Carvanha',
        released_date: '2019/08/23'
    },
    {
        dex: 319,
        family: 'Carvanha',
        released_date: '2019/08/23'
    },
    {
        dex: 320,
        family: 'Wailmer',
        released_date: '2018/04/23'
    },
    {
        dex: 321,
        family: 'Wailmer',
        released_date: '2018/04/23'
    },
    {
        dex: 322,
        released_date: '2022/06/04',
        family: 'Numel'
    },
    {
        dex: 323,
        released_date: '2022/06/04',
        family: 'Numel'
    },
    {
        dex: 324,
        family: 'Torkoal'
    },
    {
        dex: 325,
        family: 'Spoink',
        released_date: '2019/02/04'
    },
    {
        dex: 326,
        family: 'Spoink',
        released_date: '2019/02/04'
    },
    {
        dex: 327,
        family: 'Spinda'
    },
    {
        dex: 327,
        type: '_11',
        shiny_released: true,
        name_suffix: '(#1)',
        family: 'Spinda'
    },
    {
        dex: 327,
        type: '_12',
        shiny_released: true,
        name_suffix: '(#2)',
        family: 'Spinda'
    },
    {
        dex: 327,
        type: '_13',
        shiny_released: true,
        name_suffix: '(#3)',
        family: 'Spinda'
    },
    {
        dex: 327,
        type: '_14',
        shiny_released: true,
        name_suffix: '(#4)',
        family: 'Spinda'
    },
    {
        dex: 327,
        type: '_15',
        shiny_released: true,
        name_suffix: '(#5)',
        family: 'Spinda'
    },
    {
        dex: 327,
        type: '_16',
        shiny_released: true,
        name_suffix: '(#6)',
        family: 'Spinda'
    },
    {
        dex: 327,
        type: '_17',
        shiny_released: true,
        name_suffix: '(#7)',
        family: 'Spinda'
    },
    {
        dex: 327,
        type: '_18',
        shiny_released: true,
        name_suffix: '(#8)',
        family: 'Spinda'
    },
    {
        dex: 327,
        type: '_19',
        name_suffix: '(#9)',
        released_date: '2021/02/14',
        family: 'Spinda'
    },
    {
        dex: 328,
        family: 'Trapinch',
        released_date: '2019/10/11'
    },
    {
        dex: 329,
        family: 'Trapinch',
        released_date: '2019/10/11'
    },
    {
        dex: 330,
        family: 'Trapinch',
        released_date: '2019/10/11'
    },
    {
        dex: 331,
        family: 'Cacnea'
    },
    {
        dex: 332,
        family: 'Cacnea'
    },
    {
        dex: 333,
        family: 'Swablu',
        released_date: '2018/02/09'
    },
    {
        dex: 334,
        family: 'Swablu',
        released_date: '2018/02/09'
    },
    {
        dex: 334,
        family: 'Swablu',
        type: '_51',
        name_suffix: '(M)',
        released_date: '2021/05/15'
    },
    {
        dex: 335,
        shiny_released: true,
        family: 'Zangoose'
    },
    {
        dex: 336,
        shiny_released: true,
        family: 'Seviper'
    },
    {
        dex: 337,
        shiny_released: true,
        family: 'Lunatone'
    },
    {
        dex: 338,
        shiny_released: true,
        family: 'Solrock'
    },
    {
        dex: 339,
        family: 'Barboach',
        released_date: '2019/08/23'
    },
    {
        dex: 340,
        family: 'Barboach',
        released_date: '2019/08/23'
    },
    {
        dex: 341,
        family: 'Corphish',
        released_date: '2022/05/13'
    },
    {
        dex: 342,
        family: 'Corphish',
        released_date: '2022/05/13'
    },
    {
        dex: 343,
        family: 'Baltoy',
        released_date: '2020/03/27'
    },
    {
        dex: 344,
        family: 'Baltoy',
        released_date: '2020/03/27'
    },
    {
        dex: 345,
        family: 'Lileep',
        released_date: '2019/06/04'
    },
    {
        dex: 346,
        family: 'Lileep',
        released_date: '2019/06/04'
    },
    {
        dex: 347,
        family: 'Anorith',
        released_date: '2019/06/04'
    },
    {
        dex: 348,
        family: 'Anorith',
        released_date: '2019/06/04'
    },
    {
        dex: 349,
        family: 'Feebas',
        released_date: '2019/01/19'
    },
    {
        dex: 350,
        family: 'Feebas',
        released_date: '2019/01/19'
    },
    {
        dex: 351,
        shiny_released: true,
        type: '_11',
        family: 'Castform'
    },
    {
        dex: 351,
        released_date: '2021/03/24',
        type: '_13',
        family: 'Castform',
        name_suffix: '💧'
    },
    {
        dex: 352,
        family: 'Kecleon'
    },
    {
        dex: 353,
        family: 'Shuppet',
        released_date: '2017/10/31'
    },
    {
        dex: 354,
        family: 'Shuppet',
        released_date: '2017/10/31'
    },
    {
        dex: 354,
        family: 'Shuppet',
        type: '_51',
        aa_fn: 'pm354.fMEGA',
        name_suffix: '(M)',
        released_date: '2022/10/20',
        cfn1: 'https://cdn.jsdelivr.net/gh/PokeMiners/pogo_assets/Images/Pokemon%20-%20256x256/Addressable%20Assets/pm354.fMEGA.s.icon.png'
    },
    {
        dex: 355,
        family: 'Duskull',
        released_date: '2017/10/26'
    },
    {
        dex: 356,
        family: 'Duskull',
        released_date: '2017/10/26'
    },
    {
        dex: 357,
        family: 'Tropius'
    },
    {
        dex: 358,
        family: 'Chimecho',
        released_date: '2021/07/17'
    },
    {
        dex: 359,
        shiny_released: true,
        family: 'Absol'
    },
    {
        dex: 359,
        shiny_released: true,
        aa_fn: 'pm359.cFALL_2022_NOEVOLVE',
        released_date: '2022/09/27',
        isotope: '_22n',
        family: 'Absol',
        cfn1: 'https://cdn.jsdelivr.net/gh/PokeMiners/pogo_assets/Images/Pokemon%20-%20256x256/Addressable%20Assets/pm359.cFALL_2022_NOEVOLVE.s.icon.png'
    },
    {
        dex: 359,
        family: 'Absol',
        isotope: '_51',
        name_suffix: '(M)',
        aa_fn: 'pm359.fMEGA',
        released_date: '2021/10/21',
        cfn1: 'https://cdn.jsdelivr.net/gh/PokeMiners/pogo_assets/Images/Pokemon%20-%20256x256/Addressable%20Assets/pm359.fMEGA.s.icon.png'
    },
    {
        dex: 360,
        order: -1,
        family: 'Wobbuffet',
        released_date: '2018/03/22'
    },
    {
        dex: 361,
        family: 'Snorunt',
        released_date: '2017/12/21'
    },
    {
        dex: 362,
        family: 'Snorunt',
        released_date: '2017/12/21'
    },
    {
        dex: 363,
        family: 'Spheal',
        released_date: '2021/12/16'
    },
    {
        dex: 364,
        family: 'Spheal',
        released_date: '2021/12/16'
    },
    {
        dex: 365,
        family: 'Spheal',
        released_date: '2021/12/16'
    },
    {
        dex: 363,
        family: 'Spheal',
        isotope: '_21holiday',
        aa_fn: 'pm363.cHOLIDAY_2021_NOEVOLVE',
        released_date: '2021/12/16',
        cfn0:
            'https://cdn.jsdelivr.net/gh/PokeMiners/pogo_assets/Images/Pokemon%20-%20256x256/Addressable%20Assets/pm363.cHOLIDAY_2021_NOEVOLVE.s.icon.png',
        cfn1:
            'https://cdn.jsdelivr.net/gh/PokeMiners/pogo_assets/Images/Pokemon%20-%20256x256/Addressable%20Assets/pm363.cHOLIDAY_2021_NOEVOLVE.s.icon.png'
    },
    {
        dex: 366,
        family: 'Clamperl',
        released_date: '2019/02/23'
    },
    {
        dex: 367,
        family: 'Clamperl',
        released_date: '2019/02/23'
    },
    {
        dex: 368,
        family: 'Clamperl',
        released_date: '2019/02/23'
    },
    {
        dex: 369,
        family: 'Relicanth'
    },
    {
        dex: 370,
        shiny_released: true,
        family: 'Luvdisc'
    },
    {
        dex: 371,
        family: 'Bagon',
        released_date: '2019/04/13'
    },
    {
        dex: 372,
        family: 'Bagon',
        released_date: '2019/04/13'
    },
    {
        dex: 373,
        family: 'Bagon',
        released_date: '2019/04/13'
    },
    {
        dex: 374,
        family: 'Beldum',
        released_date: '2018/10/21'
    },
    {
        dex: 375,
        family: 'Beldum',
        released_date: '2018/10/21'
    },
    {
        dex: 376,
        family: 'Beldum',
        released_date: '2018/10/21'
    },
    {
        dex: 377,
        shiny_released: true,
        family: 'Regirock'
    },
    {
        dex: 378,
        shiny_released: true,
        family: 'Regice'
    },
    {
        dex: 379,
        shiny_released: true,
        family: 'Registeel'
    },
    {
        dex: 380,
        shiny_released: true,
        family: 'Latias'
    },
    {
        dex: 380,
        type: '_51',
        aa_fn: 'pm380.fMEGA',
        name_suffix: '(M)',
        released_date: '2022/05/03',
        family: 'Latias',
        cfn1: 'https://cdn.jsdelivr.net/gh/PokeMiners/pogo_assets/Images/Pokemon%20-%20256x256/Addressable%20Assets/pm380.fMEGA.s.icon.png'
    },
    {
        dex: 381,
        shiny_released: true,
        family: 'Latios'
    },
    {
        dex: 381,
        type: '_51',
        aa_fn: 'pm381.fMEGA',
        name_suffix: '(M)',
        released_date: '2022/05/03',
        family: 'Latios',
        cfn1: 'https://cdn.jsdelivr.net/gh/PokeMiners/pogo_assets/Images/Pokemon%20-%20256x256/Addressable%20Assets/pm381.fMEGA.s.icon.png'
    },
    {
        dex: 382,
        shiny_released: true,
        family: 'Kyogre'
    },
    {
        dex: 383,
        shiny_released: true,
        family: 'Groudon'
    },
    {
        dex: 384,
        shiny_released: true,
        family: 'Rayquaza'
    },
    {
        dex: 385,
        family: 'Jirachi'
    },
    {
        dex: 386,
        shiny_released: true,
        type: '_11',
        family: 'Deoxys',
        name_suffix: '(N)'
    },
    {
        dex: 386,
        shiny_released: true,
        type: '_12',
        family: 'Deoxys',
        name_suffix: '(A)'
    },
    {
        dex: 386,
        shiny_released: true,
        type: '_13',
        family: 'Deoxys',
        name_suffix: '(D)'
    },
    {
        dex: 386,
        shiny_released: true,
        type: '_14',
        family: 'Deoxys',
        name_suffix: '(S)'
    },
    {
        dex: 387,
        family: 'Turtwig',
        released_date: '2019/09/14'
    },
    {
        dex: 387,
        family: 'Turtwig_21gems',
        isotope: '_21gems1',
        aa_fn: 'pm387.cGEMS_1_2021_NOEVOLVE',
        released_date: '2021/11/16',
        cfn1:
            'https://cdn.jsdelivr.net/gh/PokeMiners/pogo_assets/Images/Pokemon%20-%20256x256/Addressable%20Assets/pm387.cGEMS_1_2021_NOEVOLVE.s.icon.png'
    },
    {
        dex: 387,
        family: 'Turtwig_21gems',
        isotope: '_21gems2',
        aa_fn: 'pm387.cGEMS_2_2021_NOEVOLVE',
        released_date: '2021/11/16',
        cfn1:
            'https://cdn.jsdelivr.net/gh/PokeMiners/pogo_assets/Images/Pokemon%20-%20256x256/Addressable%20Assets/pm387.cGEMS_2_2021_NOEVOLVE.s.icon.png'
    },
    {
        dex: 388,
        family: 'Turtwig',
        released_date: '2019/09/14'
    },
    {
        dex: 389,
        family: 'Turtwig',
        released_date: '2019/09/14'
    },
    {
        dex: 390,
        family: 'Chimchar',
        released_date: '2019/11/15'
    },
    {
        dex: 390,
        family: 'Chimchar_21gems',
        isotope: '_21gems1',
        aa_fn: 'pm390.cGEMS_1_2021_NOEVOLVE',
        released_date: '2021/11/16',
        cfn1:
            'https://cdn.jsdelivr.net/gh/PokeMiners/pogo_assets/Images/Pokemon%20-%20256x256/Addressable%20Assets/pm390.cGEMS_1_2021_NOEVOLVE.s.icon.png'
    },
    {
        dex: 390,
        family: 'Chimchar_21gems',
        isotope: '_21gems2',
        aa_fn: 'pm390.cGEMS_2_2021_NOEVOLVE',
        released_date: '2021/11/16',
        cfn1:
            'https://cdn.jsdelivr.net/gh/PokeMiners/pogo_assets/Images/Pokemon%20-%20256x256/Addressable%20Assets/pm390.cGEMS_1_2021_NOEVOLVE.s.icon.png'
    },
    {
        dex: 391,
        family: 'Chimchar',
        released_date: '2019/11/15'
    },
    {
        dex: 392,
        family: 'Chimchar',
        released_date: '2019/11/15'
    },
    {
        dex: 393,
        family: 'Piplup',
        released_date: '2020/01/18'
    },
    {
        dex: 393,
        family: 'Piplup_21Ha',
        released_date: '2021/10/15',
        isotope: '_21Ha',
        aa_fn: 'pm393.cHALLOWEEN_2021_NOEVOLVE',
        cfn1:
            'https://cdn.jsdelivr.net/gh/PokeMiners/pogo_assets/Images/Pokemon%20-%20256x256/Addressable%20Assets/pm393.cHALLOWEEN_2021_NOEVOLVE.s.icon.png'
    },
    {
        dex: 393,
        family: 'Piplup_21gems',
        isotope: '_21gems1',
        aa_fn: 'pm393.cGEMS_1_2021_NOEVOLVE',
        released_date: '2021/11/16',
        cfn1:
            'https://cdn.jsdelivr.net/gh/PokeMiners/pogo_assets/Images/Pokemon%20-%20256x256/Addressable%20Assets/pm393.cGEMS_1_2021_NOEVOLVE.s.icon.png'
    },
    {
        dex: 393,
        family: 'Piplup_21gems',
        isotope: '_21gems2',
        aa_fn: 'pm393.cGEMS_2_2021_NOEVOLVE',
        released_date: '2021/11/16',
        cfn1:
            'https://cdn.jsdelivr.net/gh/PokeMiners/pogo_assets/Images/Pokemon%20-%20256x256/Addressable%20Assets/pm393.cGEMS_2_2021_NOEVOLVE.s.icon.png'
    },
    {
        dex: 394,
        family: 'Piplup',
        released_date: '2020/01/18'
    },
    {
        dex: 395,
        family: 'Piplup',
        released_date: '2020/01/18'
    },
    {
        dex: 396,
        family: 'Starly',
        released_date: '2022/07/17'
    },
    {
        dex: 397,
        family: 'Starly',
        released_date: '2022/07/17'
    },
    {
        dex: 398,
        family: 'Starly',
        released_date: '2022/07/17'
    },
    {
        dex: 399,
        released_date: '2021/06/25',
        family: 'Bidoof'
    },
    {
        dex: 400,
        released_date: '2021/06/25',
        family: 'Bidoof'
    },
    {
        dex: 401,
        released_date: '2020/10/02',
        family: 'Kricketot'
    },
    {
        dex: 402,
        released_date: '2020/10/02',
        family: 'Kricketot'
    },
    {
        dex: 403,
        family: 'Shinx',
        released_date: '2018/10/16'
    },
    {
        dex: 403,
        isotope: '_16',
        family: 'Shinx',
        released_date: '2018/10/16'
    },
    {
        dex: 404,
        family: 'Shinx',
        released_date: '2018/10/16'
    },
    {
        dex: 405,
        family: 'Shinx',
        released_date: '2018/10/16'
    },
    {
        dex: 406,
        shiny_released: true,
        family: 'Roselia',
        order: -1
    },
    {
        dex: 407,
        shiny_released: true,
        family: 'Roselia'
    },
    {
        dex: 408,
        family: 'Cranidos',
        released_date: '2021/07/23'
    },
    {
        dex: 409,
        family: 'Cranidos',
        released_date: '2021/07/23'
    },
    {
        dex: 410,
        family: 'Shieldon',
        released_date: '2021/07/23'
    },
    {
        dex: 411,
        family: 'Shieldon',
        released_date: '2021/07/23'
    },
    {
        dex: 412,
        shiny_released: true,
        type: '_11',
        family: 'Burmy'
    },
    {
        dex: 412,
        shiny_released: true,
        type: '_12',
        family: 'Burmy'
    },
    {
        dex: 412,
        shiny_released: true,
        type: '_13',
        family: 'Burmy'
    },
    {
        dex: 413,
        shiny_released: true,
        type: '_11',
        family: 'Burmy'
    },
    {
        dex: 413,
        shiny_released: true,
        type: '_12',
        family: 'Burmy'
    },
    {
        dex: 413,
        shiny_released: true,
        type: '_13',
        family: 'Burmy'
    },
    {
        dex: 414,
        shiny_released: true,
        family: 'Burmy'
    },
    {
        dex: 415,
        family: 'Combee',
        released_date: '2022/07/22'
    },
    {
        dex: 415,
        family: 'Combee',
        type: '_01',
        released_date: '2022/07/22'
    },
    {
        dex: 416,
        family: 'Combee',
        released_date: '2022/07/22'
    },
    {
        dex: 417,
        family: 'Pachirisu'
    },
    {
        dex: 418,
        released_date: '2021/01/12',
        family: 'Buizel'
    },
    {
        dex: 419,
        released_date: '2021/01/12',
        family: 'Buizel'
    },
    {
        dex: 420,
        released_date: '2022/04/20',
        family: 'Cherubi'
    },
    {
        dex: 421,
        type: '_11',
        released_date: '2022/04/20',
        family: 'Cherubi'
    },
    {
        dex: 421,
        type: '_12',
        released_date: '2022/04/20',
        family: 'Cherubi'
    },
    {
        dex: 422,
        family: 'Shellos'
    },
    {
        dex: 423,
        family: 'Shellos'
    },
    {
        dex: 424,
        family: 'Aipom',
        released_date: '2019/05/07'
    },
    {
        dex: 425,
        family: 'Drifloon',
        released_date: '2018/10/23'
    },
    {
        dex: 426,
        family: 'Drifloon',
        released_date: '2018/10/23'
    },
    {
        dex: 426,
        family: 'Drifloon_21Ha',
        isotope: '_21Ha',
        aa_fn: 'pm426.cHALLOWEEN_2021_NOEVOLVE',
        released_date: '2021/10/15',
        cfn1:
            'https://cdn.jsdelivr.net/gh/PokeMiners/pogo_assets/Images/Pokemon%20-%20256x256/Addressable%20Assets/pm426.cHALLOWEEN_2021_NOEVOLVE.s.icon.png'
    },
    {
        dex: 427,
        family: 'Buneary',
        released_date: '2019/04/16'
    },
    {
        dex: 428,
        family: 'Buneary',
        released_date: '2019/04/16'
    },
    {
        dex: 428,
        family: 'Buneary',
        isotope: '_12',
        aa_fn: 'pm428.cAPRIL_2020_NOEVOLVE',
        released_date: '2022/04/12',
        cfn0:
            'https://cdn.jsdelivr.net/gh/PokeMiners/pogo_assets/Images/Pokemon%20-%20256x256/Addressable%20Assets/pm428.cAPRIL_2020_NOEVOLVE.s.icon.png',
        cfn1:
            'https://cdn.jsdelivr.net/gh/PokeMiners/pogo_assets/Images/Pokemon%20-%20256x256/Addressable%20Assets/pm428.cAPRIL_2020_NOEVOLVE.s.icon.png'
    },
    {
        dex: 428,
        type: '_51',
        name_suffix: '(M)',
        family: 'Buneary',
        released_date: '2021/04/04'
    },
    {
        dex: 427,
        isotope: '_12',
        family: 'Buneary',
        released_date: '2019/04/16'
    },
    {
        dex: 429,
        family: 'Misdreavus',
        released_date: '2018/12/01'
    },
    {
        dex: 430,
        shiny_released: true,
        family: 'Murkrow'
    },
    {
        dex: 431,
        released_date: '2020/05/22',
        family: 'Glameow'
    },
    {
        dex: 432,
        released_date: '2020/05/22',
        family: 'Glameow'
    },
    {
        dex: 433,
        family: 'Chimecho',
        order: -1
    },
    {
        dex: 434,
        family: 'Stunky'
    },
    {
        dex: 435,
        family: 'Stunky'
    },
    {
        dex: 436,
        family: 'Bronzor',
        released_date: '2019/05/21'
    },
    {
        dex: 437,
        family: 'Bronzor',
        released_date: '2019/05/21'
    },
    {
        dex: 438,
        order: -1,
        shiny_released: true,
        family: 'Sudowoodo'
    },
    {
        dex: 439,
        order: -1,
        shiny_released: true,
        family: 'Mr Mime'
    },
    {
        dex: 440,
        family: 'Chansey',
        order: -1,
        released_date: '2020/02/13'
    },
    {
        dex: 440,
        family: 'Chansey_07',
        isotope: '_07',
        order: -1,
        released_date: '2021/04/04'
    },
    {
        dex: 441,
        family: 'Chatot'
    },
    {
        dex: 442,
        released_date: '2020/10/24',
        family: 'Spiritomb'
    },
    {
        dex: 443,
        family: 'Gible',
        released_date: '2019/12/17'
    },
    {
        dex: 444,
        family: 'Gible',
        released_date: '2019/12/17'
    },
    {
        dex: 445,
        family: 'Gible',
        released_date: '2019/12/17'
    },
    {
        dex: 446,
        family: 'Snorlax',
        order: -1
    },
    {
        dex: 447,
        family: 'Riolu',
        released_date: '2020/02/06'
    },
    {
        dex: 448,
        family: 'Riolu',
        released_date: '2020/02/06'
    },
    {
        dex: 449,
        shiny_released: true,
        family: 'Hippowdon'
    },
    {
        dex: 450,
        shiny_released: true,
        family: 'Hippowdon'
    },
    {
        dex: 449,
        shiny_released: true,
        type: '_01',
        family: 'Hippowdon_01'
    },
    {
        dex: 450,
        shiny_released: true,
        type: '_01',
        family: 'Hippowdon_01'
    },
    {
        dex: 451,
        family: 'Skorupi',
        released_date: '2020/03/05'
    },
    {
        dex: 452,
        family: 'Skorupi',
        released_date: '2020/03/05'
    },
    {
        dex: 453,
        family: 'Croagunk',
        released_date: '2020/03/31'
    },
    {
        dex: 454,
        family: 'Croagunk',
        released_date: '2020/03/31'
    },
    {
        dex: 453,
        isotope: '_16',
        family: 'Croagunk_16',
        released_date: '2020/03/31'
    },
    {
        dex: 454,
        isotope: '_16',
        family: 'Croagunk_16',
        aa_fn: 'pm454.cFALL_2020_NOEVOLVE',
        released_date: '2022/09/27',
        cfn1: 'https://cdn.jsdelivr.net/gh/PokeMiners/pogo_assets/Images/Pokemon%20-%20256x256/Addressable%20Assets/pm454.cFALL_2020_NOEVOLVE.s.icon.png'
    },
    {
        dex: 455,
        family: 'Carnivine'
    },
    {
        dex: 456,
        released_date: '2022/1/21',
        family: 'Finneon'
    },
    {
        dex: 457,
        released_date: '2022/1/21',
        family: 'Finneon'
    },
    {
        dex: 458,
        order: -1,
        family: 'Mantine'
    },
    {
        dex: 459,
        family: 'Snover',
        released_date: '2019/12/24'
    },
    {
        dex: 460,
        family: 'Snover',
        released_date: '2019/12/24'
    },
    {
        dex: 460,
        type: '_51',
        name_suffix: '(M)',
        family: 'Snover',
        released_date: '2019/12/24'
    },
    {
        dex: 461,
        family: 'Sneasel',
        released_date: '2019/07/25'
    },
    {
        dex: 462,
        family: 'Magnemite',
        released_date: '2018/11/16'
    },
    {
        dex: 463,
        family: 'Lickitung',
        released_date: '2020/02/15'
    },
    {
        dex: 464,
        family: 'Rhyhorn',
        released_date: '2020/02/22'
    },
    {
        dex: 465,
        family: 'Tangela',
        released_date: '2020/07/24'
    },
    {
        dex: 466,
        family: 'Electabuzz',
        released_date: '2018/11/15'
    },
    {
        dex: 467,
        family: 'Magmar',
        released_date: '2018/03/22'
    },
    {
        dex: 468,
        family: 'Togepi',
        released_date: '2018/03/22'
    },
    {
        dex: 468,
        family: 'Togepi',
        isotope: '_12',
        aa_fn: 'pm468.cAPRIL_2020_NOEVOLVE',
        released_date: '2022/04/12',
        cfn0:
            'https://cdn.jsdelivr.net/gh/PokeMiners/pogo_assets/Images/Pokemon%20-%20256x256/Addressable%20Assets/pm468.cAPRIL_2020_NOEVOLVE.s.icon.png',
        cfn1:
            'https://cdn.jsdelivr.net/gh/PokeMiners/pogo_assets/Images/Pokemon%20-%20256x256/Addressable%20Assets/pm468.cAPRIL_2020_NOEVOLVE.s.icon.png'
    },
    {
        dex: 469,
        family: 'Yanma',
        released_date: '2019/09/20'
    },
    {
        dex: 470,
        family: 'Eevee',
        released_date: '2018/08/11'
    },
    {
        dex: 470,
        shiny_released: true,
        isotope: '_07',
        family: 'Eevee_07'
    },
    {
        dex: 471,
        family: 'Eevee',
        released_date: '2018/08/11'
    },
    {
        dex: 471,
        shiny_released: true,
        isotope: '_07',
        family: 'Eevee_07'
    },
    {
        dex: 471,
        isotope: '_holiday',
        family: 'Eevee_11',
        aa_fn: 'pm471.cHOLIDAY_2021_NOEVOLVE',
        released_date: '2021/12/16',
        cfn1:
            'https://cdn.jsdelivr.net/gh/PokeMiners/pogo_assets/Images/Pokemon%20-%20256x256/Addressable%20Assets/pm471.cHOLIDAY_2021_NOEVOLVE.s.icon.png'
    },
    {
        dex: 472,
        family: 'Gligar',
        released_date: '2019/09/02'
    },
    {
        dex: 473,
        family: 'Swinub',
        released_date: '2019/02/16'
    },
    {
        dex: 474,
        released_date: '2020/09/19',
        family: 'Porygon'
    },
    {
        dex: 475,
        family: 'Ralts',
        released_date: '2019/08/03'
    },
    {
        dex: 476,
        family: 'Nosepass',
        released_date: '2021/03/09'
    },
    {
        dex: 477,
        family: 'Duskull',
        released_date: '2017/10/26'
    },
    {
        dex: 478,
        family: 'Snorunt',
        released_date: '2017/12/21'
    },
    {
        dex: 479,
        family: 'Rotom'
    },
    {
        dex: 480,
        released_date: '2021/09/14',
        family: 'Uxie'
    },
    {
        dex: 481,
        released_date: '2021/09/14',
        family: 'Mesprit'
    },
    {
        dex: 482,
        released_date: '2021/09/14',
        family: 'Azelf'
    },
    {
        dex: 483,
        released_date: '2021/07/23',
        family: 'Dialga'
    },
    {
        dex: 484,
        family: 'Palkia',
        released_date: '2021/08/06'
    },
    {
        dex: 485,
        shiny_released: true,
        family: 'Heatran'
    },
    {
        dex: 486,
        released_date: '2021/06/17',
        family: 'Regigigas'
    },
    {
        dex: 487,
        type: '_12',
        released_date: '2020/10/09',
        family: 'Giratina'
    },
    {
        dex: 487,
        type: '_11',
        shiny_released: true,
        family: 'Giratina'
    },
    {
        dex: 488,
        shiny_released: true,
        family: 'Cresselia'
    },
    {
        dex: 489,
        family: 'Phione'
    },
    {
        dex: 490,
        family: 'Manaphy'
    },
    {
        dex: 491,
        shiny_released: true,
        family: 'Darkrai'
    },
    {
        dex: 492,
        family: 'Shaymin'
    },
    {
        dex: 493,
        family: 'Arceus'
    },
    {
        dex: 494,
        family: 'Victini'
    },
    {
        dex: 495,
        released_date: '2021/01/04',
        family: 'Snivy'
    },
    {
        dex: 496,
        released_date: '2021/01/04',
        family: 'Snivy'
    },
    {
        dex: 497,
        released_date: '2021/01/04',
        family: 'Snivy'
    },
    {
        dex: 498,
        released_date: '2021/07/03',
        family: 'Tepig'
    },
    {
        dex: 499,
        released_date: '2021/07/03',
        family: 'Tepig'
    },
    {
        dex: 500,
        released_date: '2021/07/03',
        family: 'Tepig'
    },
    {
        dex: 501,
        released_date: '2021/09/19',
        family: 'Oshawott'
    },
    {
        dex: 502,
        released_date: '2021/09/19',
        family: 'Oshawott'
    },
    {
        dex: 503,
        released_date: '2021/09/19',
        family: 'Oshawott'
    },
    {
        dex: 504,
        family: 'Patrat',
        released_date: '2019/09/16'
    },
    {
        dex: 505,
        family: 'Patrat',
        released_date: '2019/09/16'
    },
    {
        dex: 506,
        family: 'Lillipup',
        released_date: '2019/09/16'
    },
    {
        dex: 507,
        family: 'Lillipup',
        released_date: '2019/09/16'
    },
    {
        dex: 508,
        family: 'Lillipup',
        released_date: '2019/09/16'
    },
    {
        dex: 509,
        family: 'Purrloin'
    },
    {
        dex: 510,
        family: 'Purrloin'
    },
    {
        dex: 511,
        family: 'Pansage',
        released_date: '2022/08/05'
    },
    {
        dex: 512,
        family: 'Pansage',
        released_date: '2022/08/05'
    },
    {
        dex: 513,
        family: 'Pansear',
        released_date: '2022/07/01'
    },
    {
        dex: 514,
        family: 'Pansear',
        released_date: '2022/07/01'
    },
    {
        dex: 515,
        family: 'Panpour',
        released_date: '2022/07/22'
    },
    {
        dex: 516,
        family: 'Panpour',
        released_date: '2022/07/22'
    },
    {
        dex: 517,
        family: 'Munna',
        released_date: '2022/08/27'
    },
    {
        dex: 518,
        family: 'Munna',
        released_date: '2022/08/27'
    },
    {
        dex: 519,
        family: 'Pidove',
        released_date: '2020/07/02'
    },
    {
        dex: 520,
        family: 'Pidove',
        released_date: '2020/07/02'
    },
    {
        dex: 521,
        family: 'Pidove',
        released_date: '2020/07/02'
    },
    {
        dex: 521,
        family: 'Pidove',
        type: '_01',
        released_date: '2020/07/02'
    },
    {
        dex: 522,
        family: 'Blitzle',
        released_date: '2021/09/28'
    },
    {
        dex: 523,
        family: 'Blitzle',
        released_date: '2021/09/28'
    },
    {
        dex: 522,
        family: 'Blitzle_2021_FASHION',
        aa_fn: 'pm522.cFASHION_2021_NOEVOLVE',
        isotope: '_2021_FASHION',
        released_date: '2021/09/21',
        cfn1:
            'https://cdn.jsdelivr.net/gh/PokeMiners/pogo_assets/Images/Pokemon%20-%20256x256/Addressable%20Assets/pm522.cFASHION_2021_NOEVOLVE.s.icon.png'
    },
    {
        dex: 524,
        released_date: '2020/08/14',
        family: 'Roggenrola'
    },
    {
        dex: 525,
        released_date: '2020/08/14',
        family: 'Roggenrola'
    },
    {
        dex: 526,
        released_date: '2020/08/14',
        family: 'Roggenrola'
    },
    {
        dex: 527,
        family: 'Woobat',
        released_date: '2020/07/24'
    },
    {
        dex: 528,
        family: 'Woobat',
        released_date: '2020/07/24'
    },
    {
        dex: 529,
        family: 'Drilbur'
    },
    {
        dex: 530,
        family: 'Drilbur'
    },
    {
        dex: 531,
        family: 'Audino',
        released_date: '2021/07/17'
    },
    {
        dex: 532,
        family: 'Timburr',
        released_date: '2020/03/13'
    },
    {
        dex: 533,
        family: 'Timburr',
        released_date: '2020/03/13'
    },
    {
        dex: 534,
        family: 'Timburr',
        released_date: '2020/03/13'
    },
    {
        dex: 535,
        family: 'Tympole',
        released_date: '2021/07/17'
    },
    {
        dex: 536,
        family: 'Tympole',
        released_date: '2021/07/17'
    },
    {
        dex: 537,
        family: 'Tympole',
        released_date: '2021/07/17'
    },
    {
        dex: 538,
        family: 'Throh',
        released_date: '2021/07/17'
    },
    {
        dex: 539,
        family: 'Sawk',
        released_date: '2021/07/17'
    },
    {
        dex: 540,
        family: 'Sewaddle'
    },
    {
        dex: 541,
        family: 'Sewaddle'
    },
    {
        dex: 542,
        family: 'Sewaddle'
    },
    {
        dex: 543,
        family: 'Venipede',
        released_date: '2022/08/10'
    },
    {
        dex: 544,
        family: 'Venipede',
        released_date: '2022/08/10'
    },
    {
        dex: 545,
        family: 'Venipede',
        released_date: '2022/08/10'
    },
    {
        dex: 546,
        family: 'Cottonee',
        released_date: '2022/03/22'
    },
    {
        dex: 547,
        family: 'Cottonee',
        released_date: '2022/03/22'
    },
    {
        dex: 548,
        family: 'Petilil'
    },
    {
        dex: 549,
        family: 'Petilil'
    },
    {
        dex: 550,
        family: 'Basculin'
    },
    {
        dex: 551,
        family: 'Sandile'
    },
    {
        dex: 552,
        family: 'Sandile'
    },
    {
        dex: 553,
        family: 'Sandile'
    },
    {
        dex: 554,
        released_date: '2021/07/06',
        family: 'Darumaka'
    },
    {
        dex: 555,
        released_date: '2021/07/06',
        type: '_11',
        family: 'Darumaka'
    },
    {
        dex: 554,
        released_date: '2021/12/23',
        type: '_31',
        family: 'Darumaka_31'
    },
    {
        dex: 555,
        released_date: '2021/12/23',
        type: '_31',
        family: 'Darumaka_31'
    },
    {
        dex: 556,
        family: 'Maractus'
    },
    {
        dex: 557,
        released_date: '2020/06/25',
        family: 'Dwebble'
    },
    {
        dex: 558,
        released_date: '2020/06/25',
        family: 'Dwebble'
    },
    {
        dex: 559,
        family: 'Scraggy'
    },
    {
        dex: 560,
        family: 'Scraggy'
    },
    {
        dex: 561,
        family: 'Sigilyph'
    },
    {
        dex: 562,
        family: 'Yamask',
        released_date: '2019/10/17'
    },
    {
        dex: 562,
        type: '_31',
        family: 'Yamask_31',
        released_date: '2022/10/20'
    },
    {
        dex: 563,
        family: 'Yamask',
        released_date: '2019/10/17'
    },
    {
        dex: 564,
        family: 'Tirtouga',
        released_date: '2022/06/07'
    },
    {
        dex: 565,
        family: 'Tirtouga',
        released_date: '2022/06/07'
    },
    {
        dex: 566,
        family: 'Archen',
        released_date: '2022/06/07'
    },
    {
        dex: 567,
        family: 'Archen',
        released_date: '2022/06/07'
    },
    {
        dex: 568,
        family: 'Trubbish',
        released_date: '2021/04/20'
    },
    {
        dex: 569,
        family: 'Trubbish',
        released_date: '2021/04/20'
    },
    {
        dex: 570,
        family: 'Zorua'
    },
    {
        dex: 571,
        family: 'Zorua'
    },
    {
        dex: 572,
        family: 'Minccino',
        released_date: '2020/02/02'
    },
    {
        dex: 573,
        family: 'Minccino',
        released_date: '2020/02/02'
    },
    {
        dex: 574,
        family: 'Gothita'
    },
    {
        dex: 575,
        family: 'Gothita'
    },
    {
        dex: 576,
        family: 'Gothita'
    },
    {
        dex: 577,
        family: 'Solosis'
    },
    {
        dex: 578,
        family: 'Solosis'
    },
    {
        dex: 579,
        family: 'Solosis'
    },
    {
        dex: 580,
        family: 'Ducklett'
    },
    {
        dex: 581,
        family: 'Ducklett'
    },
    {
        dex: 582,
        family: 'Vanillite'
    },
    {
        dex: 583,
        family: 'Vanillite'
    },
    {
        dex: 584,
        family: 'Vanillite'
    },
    {
        dex: 585,
        family: 'Deerling'
    },
    {
        dex: 586,
        family: 'Deerling'
    },
    {
        dex: 587,
        family: 'Emolga'
    },
    {
        dex: 588,
        released_date: '2022/06/04',
        family: 'Karrablast'
    },
    {
        dex: 589,
        released_date: '2022/06/04',
        family: 'Karrablast'
    },
    {
        dex: 590,
        family: 'Foongus',
        released_date: '2022/07/01'
    },
    {
        dex: 591,
        family: 'Foongus',
        released_date: '2022/07/01'
    },
    {
        dex: 592,
        family: 'Frillish'
    },
    {
        dex: 593,
        family: 'Frillish'
    },
    {
        dex: 592,
        type: '_01',
        family: 'Frillish'
    },
    {
        dex: 593,
        type: '_01',
        family: 'Frillish'
    },
    {
        dex: 594,
        released_date: '2021/02/14',
        family: 'Alomomola'
    },
    {
        dex: 595,
        family: 'Joltik'
    },
    {
        dex: 596,
        family: 'Joltik'
    },
    {
        dex: 597,
        released_date: '2020/11/07',
        family: 'Ferroseed'
    },
    {
        dex: 598,
        released_date: '2020/11/07',
        family: 'Ferroseed'
    },
    {
        dex: 599,
        family: 'Klink',
        released_date: '2019/09/16'
    },
    {
        dex: 600,
        family: 'Klink',
        released_date: '2019/09/16'
    },
    {
        dex: 601,
        family: 'Klink',
        released_date: '2019/09/16'
    },
    {
        dex: 602,
        family: 'Tynamo'
    },
    {
        dex: 603,
        family: 'Tynamo'
    },
    {
        dex: 604,
        family: 'Tynamo'
    },
    {
        dex: 605,
        family: 'Elgyem',
        released_date: '2022/09/06'
    },
    {
        dex: 606,
        family: 'Elgyem',
        released_date: '2022/09/06'
    },
    {
        dex: 607,
        released_date: '2022/10/15',
        style: '--l: 15%;',
        family: 'Litwick'
    },
    {
        dex: 608,
        released_date: '2022/10/15',
        style: '--l: 15%; --t: 5%;',
        family: 'Litwick'
    },
    {
        dex: 609,
        released_date: '2022/10/15',
        style: '--l: 10%; --t: -15%; --w: 120%;',
        family: 'Litwick'
    },
    {
        dex: 610,
        family: 'Axew',
        released_date: '2022/06/04'
    },
    {
        dex: 611,
        family: 'Axew',
        released_date: '2022/06/04'
    },
    {
        dex: 612,
        family: 'Axew',
        released_date: '2022/06/04'
    },
    {
        dex: 613,
        released_date: '2020/12/18',
        family: 'Cubchoo'
    },
    {
        dex: 613,
        released_date: '2020/12/18',
        fn: 'pm0613_00_pgo_winter2020',
        isotope: '_winter2020',
        family: 'Cubchoo'
    },
    {
        dex: 614,
        released_date: '2020/12/18',
        family: 'Cubchoo'
    },
    {
        dex: 615,
        family: 'Cryogonal'
    },
    {
        dex: 616,
        family: 'Shelmet',
        released_date: '2022/06/04'
    },
    {
        dex: 617,
        family: 'Shelmet',
        released_date: '2022/06/04'
    },
    {
        dex: 618,
        family: 'Stunfisk'
    },
    {
        dex: 618,
        type: '_31',
        released_date: '2021/08/20',
        family: 'Stunfisk_31'
    },
    {
        dex: 619,
        family: 'Mienfoo'
    },
    {
        dex: 620,
        family: 'Mienfoo'
    },
    {
        dex: 621,
        family: 'Druddigon',
        released_date: '2021/12/07'
    },
    {
        dex: 622,
        family: 'Golett'
    },
    {
        dex: 623,
        family: 'Golett'
    },
    {
        dex: 624,
        family: 'Pawniard'
    },
    {
        dex: 625,
        family: 'Pawniard'
    },
    {
        dex: 626,
        family: 'Bouffalant'
    },
    {
        dex: 627,
        released_date: '2020/12/13',
        family: 'Rufflet'
    },
    {
        dex: 628,
        released_date: '2020/12/13',
        family: 'Rufflet'
    },
    {
        dex: 628,
        isotope: '_01',
        released_date: '2022/07/31',
        aa_fn: 'pm628.fHISUIAN',
        family: 'Rufflet',
        cfn1: 'https://cdn.jsdelivr.net/gh/PokeMiners/pogo_assets/Images/Pokemon%20-%20256x256/Addressable%20Assets/pm628.fHISUIAN.s.icon.png'
    },
    {
        dex: 629,
        released_date: '2021/11/09',
        family: 'Vullaby'
    },
    {
        dex: 630,
        released_date: '2021/11/09',
        family: 'Vullaby'
    },
    {
        dex: 631,
        shiny_released: true,
        family: 'Heatmor'
    },
    {
        dex: 632,
        shiny_released: true,
        family: 'Durant'
    },
    {
        dex: 633,
        released_date: '2020/08/01',
        family: 'Deino'
    },
    {
        dex: 634,
        released_date: '2020/08/01',
        family: 'Deino'
    },
    {
        dex: 635,
        released_date: '2020/08/01',
        family: 'Deino'
    },
    {
        dex: 636,
        family: 'Larvesta'
    },
    {
        dex: 637,
        family: 'Larvesta'
    },
    {
        dex: 638,
        family: 'Cobalion',
        released_date: '2020/05/18'
    },
    {
        dex: 639,
        released_date: '2020/05/19',
        family: 'Terrakion'
    },
    {
        dex: 640,
        family: 'Virizion',
        released_date: '2020/05/13'
    },
    {
        dex: 641,
        family: 'Tornadus',
        type: '_11',
        released_date: '2021/03/06'
    },
    {
        dex: 641,
        family: 'Tornadus',
        type: '_12',
        released_date: '2022/03/14'
    },
    {
        dex: 642,
        family: 'Thundurus',
        type: '_11',
        released_date: '2021/03/11'
    },
    {
        dex: 642,
        family: 'Thundurus',
        type: '_12',
        released_date: '2022/04/05'
    },
    {
        dex: 643,
        family: 'Reshiram',
        released_date: '2021/12/01'
    },
    {
        dex: 644,
        family: 'Zekrom',
        released_date: '2021/12/01'
    },
    {
        dex: 645,
        family: 'Landorus',
        type: '_11',
        released_date: '2021/03/01'
    },
    {
        dex: 645,
        family: 'Landorus',
        type: '_12',
        released_date: '2022/04/26'
    },
    {
        dex: 646,
        family: 'Kyurem',
        type: '_11',
        released_date: '2021/12/16'
    },
    {
        dex: 647,
        family: 'Keldeo'
    },
    {
        dex: 648,
        family: 'Meloetta'
    },
    {
        dex: 649,
        released_date: '2020/08/15',
        type: '_11',
        family: 'Genesect'
    },
    {
        dex: 650,
        family: 'Chespin'
    },
    {
        dex: 651,
        family: 'Chespin'
    },
    {
        dex: 652,
        family: 'Chespin'
    },
    {
        dex: 653,
        family: 'Fennekin'
    },
    {
        dex: 654,
        family: 'Fennekin'
    },
    {
        dex: 655,
        family: 'Fennekin'
    },
    {
        dex: 656,
        family: 'Froakie'
    },
    {
        dex: 657,
        family: 'Froakie'
    },
    {
        dex: 658,
        family: 'Froakie'
    },
    {
        dex: 659,
        family: 'Bunnelby',
        released_date: '2021/04/04'
    },
    {
        dex: 660,
        family: 'Bunnelby',
        released_date: '2021/04/04'
    },
    {
        dex: 661,
        family: 'Fletchling',
        released_date: '2021/03/06'
    },
    {
        dex: 662,
        family: 'Fletchling',
        released_date: '2021/03/06'
    },
    {
        dex: 663,
        family: 'Fletchling',
        released_date: '2021/03/06'
    },
    {
        dex: 664,
        family: 'Scatterbug'
    },
    {
        dex: 665,
        family: 'Scatterbug'
    },
    {
        dex: 666,
        family: 'Scatterbug'
    },
    {
        dex: 667,
        family: 'Litleo',
        released_date: '2022/02/01'
    },
    {
        dex: 668,
        family: 'Litleo',
        released_date: '2022/02/01'
    },
    {
        dex: 668,
        family: 'Litleo',
        type: '_01',
        released_date: '2022/02/01'
    },
    {
        dex: 669,
        family: 'Flabebe'
    },
    {
        dex: 670,
        family: 'Flabebe'
    },
    {
        dex: 671,
        family: 'Flabebe'
    },
    {
        dex: 672,
        family: 'Skiddo'
    },
    {
        dex: 673,
        family: 'Skiddo'
    },
    {
        dex: 674,
        family: 'Pancham'
    },
    {
        dex: 675,
        family: 'Pancham'
    },
    {
        dex: 676,
        released_date: '2022/09/27',
        aa_fn: 'pm676.fNATURAL',
        family: 'Furfrou',
        cfn1: 'https://cdn.jsdelivr.net/gh/PokeMiners/pogo_assets/Images/Pokemon%20-%20256x256/Addressable%20Assets/pm676.fNATURAL.s.icon.png'
    },
    {
        dex: 676,
        released_date: '2022/09/27',
        isotope: '_fDEBUTANTE',
        aa_fn: 'pm676.fDEBUTANTE',
        family: 'Furfrou',
        cfn1: 'https://cdn.jsdelivr.net/gh/PokeMiners/pogo_assets/Images/Pokemon%20-%20256x256/Addressable%20Assets/pm676.fDEBUTANTE.s.icon.png'
    },
    {
        dex: 676,
        released_date: '2022/09/27',
        isotope: '_fSTAR',
        aa_fn: 'pm676.fSTAR',
        family: 'Furfrou',
        cfn1: 'https://cdn.jsdelivr.net/gh/PokeMiners/pogo_assets/Images/Pokemon%20-%20256x256/Addressable%20Assets/pm676.fSTAR.s.icon.png'
    },
    {
        dex: 676,
        released_date: '2022/09/27',
        isotope: '_fDANDY',
        aa_fn: 'pm676.fDANDY',
        family: 'Furfrou',
        cfn1: 'https://cdn.jsdelivr.net/gh/PokeMiners/pogo_assets/Images/Pokemon%20-%20256x256/Addressable%20Assets/pm676.fDANDY.s.icon.png'
    },
    {
        dex: 676,
        released_date: '2022/09/27',
        isotope: '_fHEART',
        aa_fn: 'pm676.fHEART',
        family: 'Furfrou',
        cfn1: 'https://cdn.jsdelivr.net/gh/PokeMiners/pogo_assets/Images/Pokemon%20-%20256x256/Addressable%20Assets/pm676.fHEART.s.icon.png'
    },
    {
        dex: 676,
        released_date: '2022/09/27',
        isotope: '_fKABUKI',
        aa_fn: 'pm676.fKABUKI',
        family: 'Furfrou',
        cfn1: 'https://cdn.jsdelivr.net/gh/PokeMiners/pogo_assets/Images/Pokemon%20-%20256x256/Addressable%20Assets/pm676.fKABUKI.s.icon.png'
    },
    {
        dex: 676,
        released_date: '2022/09/27',
        isotope: '_fMATRON',
        aa_fn: 'pm676.fMATRON',
        family: 'Furfrou',
        cfn1: 'https://cdn.jsdelivr.net/gh/PokeMiners/pogo_assets/Images/Pokemon%20-%20256x256/Addressable%20Assets/pm676.fMATRON.s.icon.png'
    },
    {
        dex: 676,
        released_date: '2022/09/27',
        isotope: '_fDIAMOND',
        aa_fn: 'pm676.fDIAMOND',
        family: 'Furfrou',
        cfn1: 'https://cdn.jsdelivr.net/gh/PokeMiners/pogo_assets/Images/Pokemon%20-%20256x256/Addressable%20Assets/pm676.fDIAMOND.s.icon.png'
    },
    {
        dex: 676,
        released_date: '2022/09/27',
        isotope: '_fPHARAOH',
        aa_fn: 'pm676.fPHARAOH',
        family: 'Furfrou',
        cfn1: 'https://cdn.jsdelivr.net/gh/PokeMiners/pogo_assets/Images/Pokemon%20-%20256x256/Addressable%20Assets/pm676.fPHARAOH.s.icon.png'
    },
    {
        dex: 676,
        released_date: '2022/09/27',
        isotope: '_fLA_REINE',
        aa_fn: 'pm676.fLA_REINE',
        family: 'Furfrou',
        cfn1: 'https://cdn.jsdelivr.net/gh/PokeMiners/pogo_assets/Images/Pokemon%20-%20256x256/Addressable%20Assets/pm676.fLA_REINE.s.icon.png'
    },
    {
        dex: 677,
        family: 'Espurr',
        released_date: '2022/02/01'
    },
    {
        dex: 678,
        family: 'Espurr',
        released_date: '2022/02/01'
    },
    {
        dex: 678,
        family: 'Espurr',
        type: '_01',
        released_date: '2022/02/01'
    },
    {
        dex: 679,
        family: 'Honedge'
    },
    {
        dex: 680,
        family: 'Honedge'
    },
    {
        dex: 681,
        family: 'Honedge'
    },
    {
        dex: 682,
        released_date: '2022/09/24',
        family: 'Spritzee'
    },
    {
        dex: 683,
        released_date: '2022/09/24',
        family: 'Spritzee'
    },
    {
        dex: 684,
        family: 'Swirlix',
        released_date: '2022/08/05'
    },
    {
        dex: 685,
        family: 'Swirlix',
        released_date: '2022/08/05'
    },
    {
        dex: 686,
        family: 'Inkay',
        released_date: '2022/09/03'
    },
    {
        dex: 687,
        family: 'Inkay',
        released_date: '2022/09/03'
    },
    {
        dex: 688,
        family: 'Binacle',
        released_date: '2022/05/13'
    },
    {
        dex: 689,
        family: 'Binacle',
        released_date: '2022/05/13'
    },
    {
        dex: 690,
        family: 'Skrelp'
    },
    {
        dex: 691,
        family: 'Skrelp'
    },
    {
        dex: 692,
        family: 'Clauncher'
    },
    {
        dex: 693,
        family: 'Clauncher'
    },
    {
        dex: 694,
        family: 'Helioptile'
    },
    {
        dex: 695,
        family: 'Helioptile'
    },
    {
        dex: 696,
        family: 'Tyrunt'
    },
    {
        dex: 697,
        family: 'Tyrunt'
    },
    {
        dex: 698,
        family: 'Amaura'
    },
    {
        dex: 699,
        family: 'Amaura'
    },
    {
        dex: 700,
        released_date: '2021/05/25',
        family: 'Eevee'
    },
    {
        dex: 700,
        released_date: '2021/05/25',
        isotope: '_07',
        family: 'Eevee_07'
    },
    {
        dex: 701,
        family: 'Hawlucha'
    },
    {
        dex: 702,
        family: 'Dedenne'
    },
    {
        dex: 703,
        family: 'Carbink'
    },
    {
        dex: 704,
        family: 'Goomy'
    },
    {
        dex: 705,
        family: 'Goomy'
    },
    {
        dex: 706,
        family: 'Goomy'
    },
    {
        dex: 707,
        family: 'Klefki'
    },
    {
        dex: 708,
        family: 'Phantump'
    },
    {
        dex: 709,
        family: 'Phantump'
    },
    {
        dex: 710,
        family: 'Pumpkaboo'
    },
    {
        dex: 711,
        family: 'Pumpkaboo'
    },
    {
        dex: 712,
        family: 'Bergmite'
    },
    {
        dex: 713,
        family: 'Bergmite'
    },
    {
        dex: 714,
        released_date: '2022/10/20',
        family: 'Noibat'
    },
    {
        dex: 715,
        released_date: '2022/10/20',
        family: 'Noibat'
    },
    {
        dex: 716,
        released_date: '2022/10/08',
        style: '--w: 130%; --t: -23%; --l: 3%;',
        family: 'Xerneas'
    },
    {
        dex: 717,
        family: 'Yveltal',
        released_date: '2022/09/27'
    },
    {
        dex: 718,
        family: 'Zygarde'
    },
    {
        dex: 734,
        family: 'Yungoos',
        released_date: '2022/03/01',
        aa_fn: 'pm734',
        cfn0:
            'https://cdn.jsdelivr.net/gh/PokeMiners/pogo_assets/Images/Pokemon%20-%20256x256/Addressable%20Assets/pm734.s.icon.png',
        cfn1:
            'https://cdn.jsdelivr.net/gh/PokeMiners/pogo_assets/Images/Pokemon%20-%20256x256/Addressable%20Assets/pm734.s.icon.png'
    },
    {
        dex: 735,
        family: 'Yungoos',
        released_date: '2022/03/01',
        aa_fn: 'pm735',
        cfn0:
            'https://cdn.jsdelivr.net/gh/PokeMiners/pogo_assets/Images/Pokemon%20-%20256x256/Addressable%20Assets/pm735.s.icon.png',
        cfn1:
            'https://cdn.jsdelivr.net/gh/PokeMiners/pogo_assets/Images/Pokemon%20-%20256x256/Addressable%20Assets/pm735.s.icon.png'
    },
    {
        dex: 744,
        family: 'Rockruff',
        released_date: '2022/03/01',
        aa_fn: 'pm744',
        cfn0:
            'https://cdn.jsdelivr.net/gh/PokeMiners/pogo_assets/Images/Pokemon%20-%20256x256/Addressable%20Assets/pm744.s.icon.png',
        cfn1:
            'https://cdn.jsdelivr.net/gh/PokeMiners/pogo_assets/Images/Pokemon%20-%20256x256/Addressable%20Assets/pm744.s.icon.png'
    },
    {
        dex: 745,
        family: 'Rockruff',
        released_date: '2022/03/01',
        isotope: '_d',
        aa_fn: 'pm745.fMIDDAY',
        cfn0:
            'https://cdn.jsdelivr.net/gh/PokeMiners/pogo_assets/Images/Pokemon%20-%20256x256/Addressable%20Assets/pm745.fMIDDAY.s.icon.png',
        cfn1:
            'https://cdn.jsdelivr.net/gh/PokeMiners/pogo_assets/Images/Pokemon%20-%20256x256/Addressable%20Assets/pm745.fMIDDAY.s.icon.png'
    },
    {
        dex: 745,
        family: 'Rockruff',
        released_date: '2021/03/01',
        isotope: '_n',
        aa_fn: 'pm745.fMIDNIGHT',
        cfn0:
            'https://cdn.jsdelivr.net/gh/PokeMiners/pogo_assets/Images/Pokemon%20-%20256x256/Addressable%20Assets/pm745.fMIDNIGHT.s.icon.png',
        cfn1:
            'https://cdn.jsdelivr.net/gh/PokeMiners/pogo_assets/Images/Pokemon%20-%20256x256/Addressable%20Assets/pm745.fMIDNIGHT.s.icon.png'
    },
    {
        dex: 759,
        family: 'Stufful',
        released_date: '2022/04/23',
        aa_fn: 'pm759',
        cfn0:
            'https://cdn.jsdelivr.net/gh/PokeMiners/pogo_assets/Images/Pokemon%20-%20256x256/Addressable%20Assets/pm759.s.icon.png',
        cfn1:
            'https://cdn.jsdelivr.net/gh/PokeMiners/pogo_assets/Images/Pokemon%20-%20256x256/Addressable%20Assets/pm759.s.icon.png'
    },
    {
        dex: 760,
        family: 'Stufful',
        released_date: '2022/04/23',
        aa_fn: 'pm760',
        cfn0:
            'https://cdn.jsdelivr.net/gh/PokeMiners/pogo_assets/Images/Pokemon%20-%20256x256/Addressable%20Assets/pm760.s.icon.png',
        cfn1:
            'https://cdn.jsdelivr.net/gh/PokeMiners/pogo_assets/Images/Pokemon%20-%20256x256/Addressable%20Assets/pm760.s.icon.png'
    },
    {
        dex: 808,
        family: 'Meltan',
        released_date: '2019/02/05'
    },
    {
        dex: 809,
        family: 'Meltan',
        released_date: '2019/02/05'
    },
    {
        dex: 862,
        type: '_31',
        family: 'Zigzagoon_31',
        released_date: '2020/05/25'
    },
    {
        dex: 863,
        family: 'Meowth'
    },
    {
        dex: 865,
        family: 'Farfetchd'
    },
    {
        dex: 866,
        family: 'Mr_mime'
    },
    {
        dex: 867,
        released_date: '2022/10/20',
        aa_fn: 'pm867',
        family: 'Yamask_31',
        cfn1: 'https://cdn.jsdelivr.net/gh/PokeMiners/pogo_assets/Images/Pokemon%20-%20256x256/Addressable%20Assets/pm867.s.icon.png'
    }
]
