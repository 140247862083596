<template>
<div class="content flex items-center">
    <div class="shiny">Shiny</div> {{ $t('nota.notaShiny') }}
</div>
</template>

<script>
export default {

}
</script>

<style lang="scss" scoped>
.content {
    margin: 10px;
    font-size: 11px;
}
.shiny {
    background: #ffc107;
    color: white;
    justify-content: center;
    display: flex;
    align-items: center;
    padding: 0 5px;
    border-radius: 5px;
    text-transform: uppercase;
    margin-right: 5px;
    font-size: 8px;
    height: 14px !important;
}
</style>